import CartView from "@/views/CartView.vue";
import GoodsDetailView from "@/views/GoodsDetailView.vue";
import GoodsCategoryView from "@/views/GoodsCategoryView.vue";

import IndexView from "@/views/IndexView.vue";
import PriceSheetDetailView from "@/views/PriceSheetDetailView.vue";
import PriceSheetView from "@/views/PriceSheetView.vue";
import PullNewView from "@/views/PullNewView.vue";
import SearchResultView from "@/views/SearchResultView.vue";
import SettlementView from "@/views/SettlementView.vue";
import WeekPreferenceView from "@/views/WeekPreferenceView.vue";
import WholesaleAreaView from "@/views/WholesaleAreaView.vue";
import CashierView from "@/views/CashierView.vue";
import OfflinePayView from "@/views/OfflinePayView.vue";
import PaySuccessView from "@/views/success/PaySuccessView.vue";
import PersonalView from "@/views/personal/PersonalView.vue";
import UserInfoView from "@/views/personal/UserInfoView.vue";
import OrderView from "@/views/personal/OrderView.vue";
import OrderDetailView from "@/views/personal/OrderDetailView.vue";
import AfterDetailView from "@/views/personal/AfterDetailView.vue";
import RefundApplyView from "@/views/personal/RefundApplyView.vue";
import InvoiceApplyView from "@/views/personal/InvoiceApplyView.vue";
import ExpressView from "@/views/personal/ExpressView.vue";
import AfterExpressView from "@/views/personal/AfterExpressView.vue";

import AboutView from "@/views/personal/AboutView.vue";
import CouponVue from "@/views/personal/CouponVue.vue";
import SuggestView from "@/views/personal/SuggestView.vue";
import AddressView from "@/views/personal/AddressView.vue";
import CommentView from "@/views/personal/CommentView.vue";
import SafeView from "@/views/personal/SafeView.vue";
import InviteView from "@/views/personal/InviteView.vue";
import InvoiceView from "@/views/personal/InvoiceView.vue";
import MessageView from "@/views/personal/MessageView.vue";

import ProveView from "@/views/ProveView.vue";
import ProveNextView from "@/views/ProveNextView.vue";

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: IndexView,
  },
  {
    path: "/pricesheet",
    name: "pricesheet",
    component: PriceSheetView,
  },
  {
    path: "/pricesheet/:id/:name",
    name: "pricesheetdetail",
    component: PriceSheetDetailView,
  },
  {
    path: "/weekpreference",
    name: "weekpreference",
    component: WeekPreferenceView,
  },
  {
    path: "/wholesalearea",
    name: "wholesalearea",
    component: WholesaleAreaView,
  },
  {
    path: "/searchresult/:keywords",
    name: "searchresult",
    component: SearchResultView,
  },
  {
    path: "/goodsdetail/:id",
    name: "goodsdetail",
    component: GoodsDetailView,
  },

  {
    path: "/goodsdetailtwo/:id/:is_youhui/:common_id",
    name: "goodsdetailtwo",
    component: GoodsDetailView,
  },

  {
    path: "/goodscategory/:name/:value",
    name: "goodscategory",
    component: GoodsCategoryView,
  },

  {
    path: "/cart",
    name: "cart",
    component: CartView,
  },
  {
    path: "/settlement",
    name: "settlement",
    component: SettlementView,
  },
  {
    path: "/cashier/:orderId",
    name: "cashier",
    component: CashierView,
  },
  {
    path: "/offlinepay/:orderId",
    name: "offlinepay",
    component: OfflinePayView,
  },
  {
    path: "/paysuccess/:orderId",
    name: "paysuccess",
    component: PaySuccessView,
  },
  {
    path: "/personal",
    name: "personal",
    component: PersonalView,
    redirect: {
      path: "/personal/userinfo",
    },
    children: [
      {
        path: "userinfo",
        component: UserInfoView,
      },
      {
        path: "order/list",
        name: "orderlist",
        component: OrderView,
        meta: {
          keepAlive: true,
        },
      },
      {
        name: "orderdetail",
        path: "orderdetail/:orderId",
        component: OrderDetailView,
      },
      {
        name: "afterdetail",
        path: "afterdetail/:orderId",
        component: AfterDetailView,
      },

      {
        name: "refundapply",
        path: "refundapply/:orderId",
        component: RefundApplyView,
      },
      {
        name: "invoiceapply",
        path: "invoiceapply/:orderId",
        component: InvoiceApplyView,
      },
      {
        name: "express",
        path: "express/:orderId",
        component: ExpressView,
      },

      {
        name: "afterexpress",
        path: "afterexpress/:orderId",
        component: AfterExpressView,
      },


      {
        path: "about",
        component: AboutView,
      },
      {
        path: "message/list",
        name: "messagelist",
        component: MessageView,
      },
      {
        path: "coupon",
        component: CouponVue,
      },
      {
        path: "suggest",
        component: SuggestView,
      },
      {
        path: "address",
        component: AddressView,
      },
      {
        path: "comment",
        component: CommentView,
      },
      {
        path: "safe",
        component: SafeView,
      },
      {
        path: "invite",
        component: InviteView,
      },
      {
        path: "invoice",
        component: InvoiceView,
      },
    ],
  },
  {
    path: "/pullnew",
    name: "pullnew",
    component: PullNewView,
  },
  {
    path: "/prove",
    name: "prove",
    component: ProveView,
  },
  {
    path: "/provenext",
    name: "provenext",
    component: ProveNextView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/AgreementView.vue"),
  },
  {
    path: "/salecontract",
    name: "salecontract",
    component: () => import("../views/SaleContractView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
  });
  // @ts-ignore
  router.matcher = newRouter.matcher;
}

export default router;
