
import {
  cancelAfterSaleOrder,
  getAfterSaleOrders,
  ROrderAfterSale,
  setAfterSaleExpress,
} from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AfterList",
  components: { Pager },
  props: {
    type: {
      type: Number,
    },
    keywords: {
      type: String,
    },
  },

  data() {
    return {
      pager: undefined as any,
      page: 1,
      orders: [] as ROrderAfterSale[],
      sn: "",
      logisticsCoId: 4,
      statusArr: {
        "-1": "申请中",
        "0": "退款中",
        "1": "已关闭",
        "2": "退款成功",
      },
      cancelFinishVisible: false,
      commentVisible: false,
      currentOrderId: -1 as any,
      warnDisable: false,
      warnMessage: "",
    };
  },
  watch: {
    page() {
      this.getOrders();
    },
    keywords() {
      this.page = 1;
      this.getOrders();
    },
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      const orders = await getAfterSaleOrders({
        page: this.page,
        type: this.type,
        goodsName: this.keywords,
      });
      this.pager = orders;
      this.orders = orders.list;
    },

    onSureCancel(order) {
      this.cancelFinishVisible = true;
      this.currentOrderId = order.id;
    },

    async onCancel() {
      await cancelAfterSaleOrder(this.currentOrderId);
      this.cancelFinishVisible = false;
      await this.getOrders();
    },

    // async onCancel(order: ROrderAfterSale) {
    //   await cancelAfterSaleOrder(order.id);
    // },

    setSureExpressInfo(order) {
      this.commentVisible = true;
      this.currentOrderId = order.id;
    },

    async setExpressInfo() {
      console.log(this.logisticsCoId);
      if (this.sn == "") {
        this.jumpWarn("请填写快递单号");
        return;
      }
      await setAfterSaleExpress(this.currentOrderId, {
        logisticsCoId: this.logisticsCoId,
        sn: this.sn,
      });
      this.commentVisible = false;
      await this.getOrders();
    },

    jumpWarn(title: any) {
      this.warnMessage = title;
      this.warnDisable = true;
      setTimeout(() => {
        this.warnDisable = false;
      }, 1000);
    },

    orderDetail(order) {
      //this.$router.push("./orderdetail");
      this.$router.push({ name: "afterdetail", params: { orderId: order.id } });
    },
  },
});
