
import { RInvoice } from "@/api/zmkm-api";
import { defineComponent } from "vue";
import InvoiceDetail from "./components/InvoiceDetail.vue";
import InvoiceList from "./components/InvoiceList.vue";

import InvoicePreview from "./components/InvoicePreview.vue";

export default defineComponent({
  name: "invoiceview",
  components: {
    InvoiceList,
    InvoiceDetail,
    InvoicePreview,
  },
  data() {
    return {
      tabCurrentIndex: 0,
      tabs: [
        { name: "tab_1", label: "已开发票", status: 1, enable: true },
        { name: "tab_2", label: "申请中发票", status: 0, enable: false },
      ],
      invoice: undefined as any as RInvoice | undefined,
      listVisible: true,
      detailVisible: false,
      previewVisible: false,
    };
  },
  methods: {
    onTabSelect(index: number) {
      this.tabCurrentIndex = index;
      this.tabs[index].enable = true;
    },
    viewDetail(invoice: RInvoice) {
      this.invoice = invoice;
      this.listVisible = false;
      this.detailVisible = true;
      this.previewVisible = false;
    },
    preview(invoice: RInvoice) {
      this.invoice = invoice;
      this.listVisible = false;
      this.detailVisible = false;
      this.previewVisible = true;
    },
    back() {
      console.log("abcd");
      this.invoice = undefined;
      this.listVisible = true;
      this.detailVisible = false;
      this.previewVisible = false;
    },
  },
});
