import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "checkbox",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("img", {
      style: {"background-color":"#f0f0f0","border-radius":"2px","border":"1px solid #f0f0f0"},
      class: "checkbox-icon",
      src: _ctx.isChecked ? 'image/checkbox-icon1.png' : 'image/checkbox-icon2.png',
      alt: ""
    }, null, 8, _hoisted_1)
  ]))
}