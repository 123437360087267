
import { OrdersReceiver, Reason } from "@/api/zmkm";
import {
  addOrderComment,
  cancelOrder,
  confirmOrder,
  getOrderDetail,
  getReasons,
  ROrderDesc,
  ROrderDetail,
} from "@/api/zmkm-api";
import CountDown from "@/components/CountDown.vue";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { areaIdToString } from "@/utils/area.utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderDetail",
  components: { Uploader, CountDown },
  data() {
    return {
      order: undefined as ROrderDetail | undefined,
      cancelVisible: false,
      cancelFinishVisible: false,
      commentVisible: false,
      commentFinishVisible: false,
      confirmVisible: false,
      reasons: [] as Reason[],
      reasonId: 0,
      commentOrderDesc: undefined as ROrderDesc | undefined,
      commentLevel: 5,
      commentDesc: "",
      commentDescImageFiles: [] as UploaderFile[],
      commentDescImage: "",

      statusArr: {
        "0": "待付款",
        "1": "审核中",
        "2": "待发货",
        "3": "待收货",
        "4": "已完成",
        "5": "已评价",
        "6": "已关闭",
        "7": "支付超时",
        "8": "售后中",
        "9": "已售后",
        "10": "取消售后",
      },
    };
  },
  async mounted() {
    this.reasons = await getReasons();
    this.reasonId = this.reasons[0]?.id || 0;
    await this.getOrderDetail();
  },
  methods: {
    async getOrderDetail() {
      this.order = await getOrderDetail(
        parseInt(this.$route.params.orderId as string)
      );
    },
    addressToAreaString(receiver?: OrdersReceiver) {
      if (!receiver) {
        return "";
      }

      return [
        areaIdToString(receiver.province, 1),
        areaIdToString(receiver.city, 2),
        areaIdToString(receiver.district, 3),
        receiver.address,
      ].join("");
    },

    navBack() {
      this.$router.go(-1);
    },

    async onCancel() {
      await cancelOrder(this.order!.id, {
        reasonId: this.reasonId,
      });
      this.cancelFinishVisible = true;
    },

    onCancelFinish(type: "close" | "home" | "view") {
      this.cancelFinishVisible = false;
      if (type === "home") {
        this.$router.replace("/");
      } else if (type === "view") {
        this.getOrderDetail();
      }
    },

    async onComment() {
      console.log(11111);
      this.commentDescImage =
        // @ts-ignore
        await this.$refs.commentDescImageFilesUploader.doUpload();
      await addOrderComment(this.order!.id, {
        ordersDescId: this.commentOrderDesc!.id,
        descText: this.commentDesc,
        descImage: this.commentDescImage,
        lever: this.commentLevel,
      });
      this.commentVisible = false;
      this.commentFinishVisible = true;
      this.commentDesc = "";
    },

    onCommentFinish(type: "close" | "home" | "view") {
      this.commentFinishVisible = false;
      if (type === "home") {
        this.$router.replace("/");
      } else if (type === "view") {
        this.$router.replace("/personal/comment");
      }
    },

    async onConfirm() {
      await confirmOrder(this.order!.id);
      await this.getOrderDetail();
      this.confirmVisible = false;
    },

    onRefund() {
      this.$router.push({
        name: "refundapply",
        params: { orderId: this.order?.id },
      });
    },

    onInvoice() {
      this.$router.push({
        name: "invoiceapply",
        params: { orderId: this.order?.id },
      });
    },

    onExpress() {
      this.$router.push({
        name: "express",
        params: { orderId: this.order?.id },
      });
    },

    onPay() {
      this.$router.push({
        name: "cashier",
        params: { orderId: this.order?.id },
      });
    },

    //重新购物
    onBuy() {
      this.$router.push("/");
    },

    //打开客服
    onKefu() {
      window.open(localStorage.getItem("kfUrl") as string, "_blank"); // 新窗口打开外链接
    },
  },
});
