
import { getInvoiceDetail, RInvoice } from "@/api/zmkm-api";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "invoicedetail",
  props: {
    invoice: {
      type: Object as PropType<RInvoice>,
    },
    status: {
      type: Number,
    },
  },
  watch: {
    invoice() {
      console.log(this.invoice, "ressutl");

      this.getInvoiceDetail();
    },
  },
  data() {
    return {
      invoiceInfo: {},
    };
  },
  mounted() {
    this.getInvoiceDetail();
  },
  methods: {
    async getInvoiceDetail() {
      console.log("---------------------------------");
      if (this.invoice?.id) {
        const invoices = await getInvoiceDetail(this.invoice?.id as number);
        this.invoiceInfo = invoices;
      }
    },

    goBack() {
      this.$emit("close");
    },
  },
});
