
import { Goods } from "@/api/zmkm";
import { RGoods, searchGoods } from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchResultView",
  components: { Pager },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      goodsList: [] as RGoods[],
      keywords: "",
    };
  },
  watch: {
    "$route.params.keywords"(newValue: string) {
      this.keywords = newValue;
      this.searchGoods();
    },
    page() {
      this.searchGoods();
    },
  },
  mounted() {
    this.keywords = this.$route.params.keywords as string;
    this.searchGoods();
  },
  methods: {
    async searchGoods() {
      const goodsList = await searchGoods({
        keywords: this.keywords?.trim(),
        page: this.page,
      });
      this.pager = goodsList;
      this.goodsList = goodsList.list;
    },
    onDetail(goods: Goods) {
      this.$router.push({ name: "goodsdetail", params: { id: goods.id } });
    },
  },
});
