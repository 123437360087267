
import { defineComponent } from "vue";

export default defineComponent({
  name: "CountDown",
  props: {
    startTime: String,
    endTime: String,
    duration: Number,
  },
  emits: {
    end() {
      return true;
    },
  },
  mounted() {
    this.start();
  },
  unmounted() {
    this.stopTimer();
  },
  data() {
    return {
      timer: 0,
      seconds: 0,
    };
  },
  watch: {
    startTime() {
      this.start();
    },
    endTime(newValue: string) {
      this.seconds = (new Date(newValue).getTime() - Date.now()) / 1000;
    },
  },
  computed: {
    value() {
      if (this.seconds >= 0) {
        return `${Math.floor(this.seconds / 60)
          .toString()
          .padStart(2, "0")}:${(this.seconds % 60)
          .toString()
          .padStart(2, "0")}`;
      }
      return "";
    },
  },
  methods: {
    start() {
      if (this.startTime && this.duration) {
        const startTime = new Date(this.startTime);
        const now = new Date();
        const diff = Math.floor((now.getTime() - startTime.getTime()) / 1000);
        this.seconds = this.duration - diff;
        if (this.seconds >= 0) {
          this.startTimer();
        }
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.seconds--;
        if (this.seconds <= 0) {
          this.stopTimer();
          this.$emit("end");
        }
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = 0;
      }
    },
  },
});
