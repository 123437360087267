
import { ApiResponseError, memberFeedback, uploadFile } from "@/api/zmkm-api";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "suggestview",
  components: { Uploader },
  data: () => {
    return {
      name: "",
      tel: "",
      desc: "",
      imageFiles: [] as UploaderFile[],
    };
  },
  computed: {
    enabled() {
      return (
        this.name.trim().length &&
        this.tel.trim().length &&
        (this.desc.trim().length || this.imageFiles.length)
      );
    },
    ...mapState(["baseUrl"]),
  },
  methods: {
    onImageFileClick() {
      document.getElementById("com-file-btn")?.click();
    },
    async onSubmit() {
      let imageUrl: string | undefined;
      if (this.imageFiles.length) {
        imageUrl = await uploadFile(this.imageFiles[0].file);
      }

      if (this.name == "") {
        this.$message.show({ message: "请输入姓名！" });
        return;
      }

      if (this.tel == "") {
        this.$message.show({ message: "请输入手机号！" });
        return;
      }
      if (!/^1\d{10}$/.test(this.tel)) {
        this.$message.show({ message: "手机号格式不正确！" });
        return;
      }

      if (this.desc == "") {
        this.$message.show({ message: "请输入意见内容！" });
        return;
      }

      var apiUrl = localStorage.getItem("apiUrl") as any;

      try {
        var rr = await memberFeedback({
          name: this.name,
          tel: this.tel,
          desc: this.desc,
          image: this.baseUrl +'/' + imageUrl,
        });

        this.$message.show({ message: "提交成功" });
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.$message.show({ message: e.response.message! });
        }
      }
    },
  },
});
