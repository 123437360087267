
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import MainNav from "@/components/Nav.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndexView",
  components: {
    Footer,
    Header,
    MainNav,
  },
  beforeMount() {
    this.$store.dispatch("init");
  },

  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      //document.body.scrollTop = 0;
      //document.documentElement.scrollTop = 0;
    },
  },
});
