
import { defineComponent } from "vue";
import AfterSubList from "./AfterSubList.vue";
export default defineComponent({
  name: "AfterList",
  components: { AfterSubList },
  data() {
    return {
      keywords: "",
      type: 0,
      type1: 0,
      type2: 1,
    };
  },
});
