
import { getOrderDetail, ROrderDetail, alipayPc, wxPc } from "@/api/zmkm-api";
import CountDown from "@/components/CountDown.vue";
import { defineComponent } from "vue";

import QRCode from "qrcodejs2"; // 生成二维码

export default defineComponent({
  name: "CashierView",
  components: { CountDown },
  data: () => {
    return {
      order: undefined as ROrderDetail | undefined,
      payType: "offline" as "offline" | "wx" | "zfb",
      form: "" as string,
      isPay: 0 as number,
      maskVisual: false as boolean
    };
  },
  async mounted() {
    this.order = await getOrderDetail(
      parseInt(this.$route.params.orderId as string)
    );
  },
  methods: {
    onEnd() {
      this.$router.replace("/");
    },
    async onPay() {
      if (this.payType === "wx") {
        let result = (await wxPc(
          parseInt(this.$route.params.orderId as string)
        )) as any;

        this.maskVisual = true;

        let obj = this.$refs.payQrcode as any;
        obj.innerHTML = '';

        new QRCode("payQrcode", {
          // 此处的qrcode为上面div的id
          text: JSON.parse(result).code_url,
          width: 120,
          height: 120,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
          background: "#f0f",
          foreground: "#ff0",
        });
      }

      if (this.payType === "zfb") {
        let resutl = await alipayPc(
          parseInt(this.$route.params.orderId as string)
        );

        this.form = resutl as string;
        console.log(resutl);

        this.$nextTick(() => {
          document.forms[0].submit();
        });
      }

      if (this.payType === "offline") {
        this.$router.push({
          name: "offlinepay",
          params: { orderId: this.order!.id },
        });
      }
    },
    //放弃支付
    refusePay(){
      this.$router.go(-2);
    },

    //完成支付
    completePay(){
      this.$router.push("/paysuccess/" + this.order!.id);
    }

  },
});
