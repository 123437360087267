
import { ApiResponsePageData } from "@/api/api-response";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Pager",
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page(page: number) {
      return true;
    },
  },
  props: {
    pager: {
      type: Object as PropType<ApiResponsePageData<any>>,
    },
  },
  methods: {
    onPrev() {
      this.$emit("page", Math.max(1, this.current - 1));
    },
    onNext() {
      this.$emit("page", Math.min(this.current + 1, this.totalPageCount));
    },
    onPage(page: number) {
      this.$emit("page", page);
    },
  },
  computed: {
    current() {
      return this.pager?.page || 0;
    },
    totalPageCount() {
      return this.pager
        ? Math.min(Math.ceil(this.pager.total / this.pager.size))
        : 0;
    },
    isFirst() {
      return this.current === 1;
    },
    isLast() {
      return this.current === this.totalPageCount;
    },
    pages() {
      if (!this.pager) {
        return [];
      } else {
        const maxPageCount = 5;
        const pageCount = Math.ceil(this.pager.total / this.pager.size);
        let pages: number[] = [];
        if (pageCount) {
          const { page } = this.pager;
          if (pageCount <= maxPageCount || page <= maxPageCount - 1) {
            pages = new Array(Math.min(pageCount, maxPageCount))
              .fill(0)
              .map((_, index) => index + 1);
          } else {
            const all = [page - 2, page - 1, page, page + 1, page + 2];
            while (all.length) {
              const p = all.shift()!;
              if (p > pageCount) {
                pages.unshift(pages[0] - 1);
              } else {
                pages.push(p);
              }
            }
          }
        }
        return pages;
      }
    },
  },
});
