
import { FirmBank } from "@/api/zmkm";
import {
  getCfgFirmBank,
  getOrderDetail,
  payOrderOffline,
  ROrderDetail,
  uploadFile,
} from "@/api/zmkm-api";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "OfflinePayView",
  components: { Uploader },
  data: () => {
    return {
      order: undefined as ROrderDetail | undefined,
      firmBank: undefined as FirmBank | undefined,
      compellation: "",
      bankCard: "",
      payImage: "",
      payImages: [] as UploaderFile[],
    };
  },
  async mounted() {
    this.order = await getOrderDetail(
      parseInt(this.$route.params.orderId as string)
    );
    this.firmBank = await getCfgFirmBank();
  },
  computed: {
    ...mapState(["baseUrl"]),
  },
  methods: {
    onCancel() {
      //this.$router.back();

      this.$router.go(-4);
    },
    async onPay() {
      console.log(this.baseUrl, "----------5555------------");

      if (!this.compellation.trim().length) {
        this.$message.show({ message: "请输入您的姓名" });
        return;
      }
      if (!this.bankCard.trim().length) {
        this.$message.show({ message: "请输入您的银行账号" });
        return;
      }
      if (!this.payImages.length) {
        this.$message.show({ message: "请上传凭证" });
        return;
      }
      this.payImage = await uploadFile(this.payImages[0].file);
      await payOrderOffline(this.order!.id, {
        compellation: this.compellation,
        bankCard: this.bankCard,
        payImage: this.baseUrl + "/" + this.payImage,
        from: 1,
      });
      this.$router.push("/paysuccess/" + this.order!.id);
    },
  },
});
