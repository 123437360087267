
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "PullNewView",
  components: {},
  data() {
    return {
      talkId: "",
      imgNoLogin: "image/goods-detail-photo.png",
      imgLogin: "image/goods-detail-photo1.png",
    };
  },

  mounted() {},

  computed: {
    ...mapState(["memberInfo"]),
  },

  methods: {
    //注册
    register() {
      console.log(this.memberInfo)

      console.log(
        this.getQueryVariable("talk"),
        "------------------test data------------"
      );
      if (this.memberInfo) {
        //this.isRegisterShow = true;
        this.$router.push("/personal/invite");
      } else {
        if (this.getQueryVariable("talk")) {
          this.talkId = this.getQueryVariable("talk") as string;

          this.$store.commit("setTalk", this.talkId);
          console.log(
            this.$store.state.talkId,
            "------------------------------"
          );
          //this.$router.push('/');
        }
        this.$message.show({ message: "点击右上角免费注册加入我们" })
      }
    },

    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
});
