
import { ApiResponsePageData } from "@/api/api-response";
import { ExpressAddress } from "@/api/zmkm";
import {
  addExpressAddress,
  deleteExpressAddress,
  getExpressAddresses,
  updateExpressAddress,
} from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";
import EditAddress from "./components/EditAddress.vue";
import { areaIdToString } from "@/utils/area.utils";

export default defineComponent({
  name: "AddressView",
  components: {
    Pager,
    EditAddress,
  },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      addresses: [] as ExpressAddress[],
      address: undefined as ExpressAddress | undefined,
      listVisible: true,
      addVisible: false,
      editVisible: false,
    };
  },
  mounted() {
    this.getAddresses();
  },
  watch: {
    page() {
      this.getAddresses();
    },
  },
  methods: {
    addressToAreaString(address: ExpressAddress) {
      return [
        areaIdToString(address.province, 1),
        areaIdToString(address.city, 2),
        areaIdToString(address.district, 3),
        address.address,
      ].join("");
    },
    async getAddresses() {
      const addresses = (await getExpressAddresses({
        page: this.page,
      })) as ApiResponsePageData<ExpressAddress>;
      this.pager = addresses;
      this.addresses = addresses.list;
      console.log(this.addresses,"wjq is god")
    },
    //添加地址
    onAdd() {
      this.listVisible = false;
      this.addVisible = true;
      this.editVisible = false;
    },
    //编辑地址
    onEdit(address: ExpressAddress) {
      this.address = address;
      this.listVisible = false;
      this.addVisible = false;
      this.editVisible = true;
    },
    async onSave(address: ExpressAddress) {
      await updateExpressAddress(this.address!.id, address);
      await this.getAddresses();

      this.listVisible = true;
      this.addVisible = false;
      this.editVisible = false;
      this.$message.show({ message: "提交成功" });
    },
    async onCreate(address: ExpressAddress) {
      await addExpressAddress(address);
      await this.getAddresses();
      this.onClose();
    },
    async onSetDefault(address: ExpressAddress) {
      await updateExpressAddress(address.id, {
        statusDefault: 1,
      });
      await this.getAddresses();
    },
    async onDelete(address: ExpressAddress) {
      await deleteExpressAddress(address.id);
      await this.getAddresses();
    },
    onClose() {
      this.listVisible = true;
      this.addVisible = false;
      this.editVisible = false;
    },
  },
});
