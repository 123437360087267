
import { getAfterOrderLogistics, LogisticsDynamic } from "@/api/zmkm-api";
import { defineComponent } from "vue";
export default defineComponent({
  name: "AfterExpressView",
  data() {
    return {
      expressInfo: undefined as LogisticsDynamic | undefined,
    };
  },
  created() {
    this.getExpressInfo();
  },
  methods: {
    async getExpressInfo() {
      this.expressInfo = await getAfterOrderLogistics(
        parseInt(this.$route.params.orderId as string)
      );
    },

    navBack() {
      this.$router.go(-1);
    },
  },
});
