
import { defineComponent, PropType } from "vue";

type Option = Record<string, any>;

export default defineComponent({
  name: "Selector",
  props: {
    modelValue: {
      type: Number as PropType<string | number>,
    },
    labelKey: {
      type: String,
      default: () => "key",
    },
    valueKey: {
      type: String,
      default: () => "value",
    },
    options: {
      type: Object as PropType<Option[]>,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "update:modelValue"(value: string | number) {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    change(value: string | number) {
      return true;
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    onSelect(option: Option) {
      this.open = false;
      const value = option[this.valueKey];
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
    onClose() {
      this.open = false;
    },
  },
});
