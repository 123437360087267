
import { Authentication } from "@/api/zmkm";
import { getCfgAuthentication, memberAuth } from "@/api/zmkm-api";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "ProveView",
  components: { Uploader },
  data() {
    return {
      hasBusinessLicensePhoto: true,
      noBusinessLicensePhoto: false,
      businessLicensePhoto: "",
      businessLicensePhotoFiles: [] as UploaderFile[],
      medicalInstitutionLicensePhoto: "",
      medicalInstitutionLicensePhotoFiles: [] as UploaderFile[],
      idCardPhoto: "",
      idCardPhotoFiles: [] as UploaderFile[],
      drugBusinessLicensePhoto: "",
      drugBusinessLicensePhotoFiles: [] as UploaderFile[],
      drugBusinessLicenseCommunityDescPhoto: "",
      drugBusinessLicenseCommunityDescPhotoFiles: [] as UploaderFile[],
      tyPhoto: "",
      tyPhotoFiles: [] as UploaderFile[],
      
      warnVisible: false,
      previewVisible: false,
      imageList: [],
      imageIndex: 0,
      imageUrl: "",
      authentication: {} as Authentication,
      firmType:0,
      fileArr:['1'],

      farenBookPhoto: "",
      farenBookPhotoFiles: [] as UploaderFile[],

      weituoCardPhoto: "",
      weituoCardPhotoFiles: [] as UploaderFile[],

      jigouCardPhoto: "",
      jigouCardPhotoFiles: [] as UploaderFile[],

      shiyeFarenBookPhoto: "",
      shiyeFarenBookPhotoFiles: [] as UploaderFile[],

      minbanNoBusinessPhoto: "",
      minbanNoBusinessPhotoFiles: [] as UploaderFile[],

      yaopinManagePhoto: "",
      yaopinManagePhotoFiles: [] as UploaderFile[],

      gspBookPhoto: "",
      gspBookPhotoFiles: [] as UploaderFile[],

      billingPhoto: "",
      billingPhotoFiles: [] as UploaderFile[],

      yaopinMakePhoto: "",
      yaopinMakePhotoFiles: [] as UploaderFile[],

    };
  },
  async mounted() {
    let preveinfo = JSON.parse(localStorage.getItem("preveinfo") as string);
    this.firmType = preveinfo.firmType;
    this.authentication = await getCfgAuthentication();
    if(preveinfo.firmType == 1){
      this.fileArr = ['1','2','3','4'];
    }
    if(preveinfo.firmType == 2){
      this.fileArr = ['2','3','4','5'];
    }
    if(preveinfo.firmType == 3){
      this.fileArr = ['6','2','3','4'];
    }
    if(preveinfo.firmType == 4){
      this.fileArr = ['7','2','3','4'];
    }
    if(preveinfo.firmType == 5){
      this.fileArr = ['1','8','9','10','3','4'];
    }
    if(preveinfo.firmType == 6){
      this.fileArr = ['1','8','9','10','3','4'];
    }
    if(preveinfo.firmType == 7){
      this.fileArr = ['1','8','12','10','3','4'];
    }
  },
  computed: {
    ...mapState(["cfg", "baseUrl"]),
  },
  methods: {
    previewImage(url) {
      console.log(url)
      //this.imageUrl = url.split(',')[0];
      this.imageList = url.split(",");
      console.log(this.imageList);
      this.imageIndex = 0;
      this.previewVisible = true;
    },

   
    async onSubmit() {
      let preveinfo = JSON.parse(localStorage.getItem("preveinfo") as string);
      
      //上传营业执照 1
      if(this.fileArr.indexOf('1') != -1){
        if (this.hasBusinessLicensePhoto) {
          if (!this.businessLicensePhotoFiles.length) {
            this.$message.show({ message: "请先上传营业执照" });
            return;
          }
          this.businessLicensePhoto =
            // @ts-ignore
            await this.$refs.businessLicensePhotoFiles.doUpload();
        }
      }
      if(this.fileArr.indexOf('2') != -1){
        //医疗机构许可证 2
        if (!this.medicalInstitutionLicensePhotoFiles.length) {
          this.$message.show({
            message: "请先上传医疗机构许可证",
          });
          return;
        }
        this.medicalInstitutionLicensePhoto =
          // @ts-ignore
          await this.$refs.medicalInstitutionLicensePhotoFiles.doUpload();
      }

      if(this.fileArr.indexOf('3') != -1){
        //法人授权委托书 3
        if (!this.farenBookPhotoFiles.length) {
          this.$message.show({
            message: "请先上传法人授权委托书",
          });
          return;
        }
        this.farenBookPhoto =
          // @ts-ignore
          await this.$refs.farenBookPhotoFiles.doUpload();
      }

      if(this.fileArr.indexOf('4') != -1){
        //委托人员身份证复印件 4
        if (!this.weituoCardPhotoFiles.length) {
          this.$message.show({
            message: "请先上传委托人员身份证复印件",
          });
          return;
        }
        this.weituoCardPhoto =
          // @ts-ignore
          await this.$refs.weituoCardPhotoFiles.doUpload();
      }

      if(this.fileArr.indexOf('5') != -1){
          //机构法人身份证复印件 5
        if (!this.jigouCardPhotoFiles.length) {
          this.$message.show({
            message: "请先上传机构法人身份证复印件",
          });
          return;
        }
        this.jigouCardPhoto =
          // @ts-ignore
          await this.$refs.jigouCardPhotoFiles.doUpload();
      }

      
      if(this.fileArr.indexOf('6') != -1){
        //事业单位法人证 6
        if (!this.shiyeFarenBookPhotoFiles.length) {
          this.$message.show({
            message: "请先上传事业单位法人证",
          });
          return;
        }
        this.shiyeFarenBookPhoto =
          // @ts-ignore
          await this.$refs.shiyeFarenBookPhotoFiles.doUpload();
      }

      if(this.fileArr.indexOf('7') != -1){
        //民办非企业单位登记证书 7
        if (!this.minbanNoBusinessPhotoFiles.length) {
          this.$message.show({
            message: "请先上传民办非企业单位登记证书",
          });
          return;
        }
        this.minbanNoBusinessPhoto =
          // @ts-ignore
          await this.$refs.minbanNoBusinessPhotoFiles.doUpload();
      }
      if(this.fileArr.indexOf('8') != -1){
        //药品经营许可证 8
        if (!this.yaopinManagePhotoFiles.length) {
          this.$message.show({
            message: "请先上传药品经营许可证",
          });
          return;
        }
        this.yaopinManagePhoto =
          // @ts-ignore
          await this.$refs.yaopinManagePhotoFiles.doUpload();
      }

      if(this.fileArr.indexOf('9') != -1){
        //GSP证书 9
        if (!this.gspBookPhotoFiles.length) {
          this.$message.show({
            message: "请先上传GSP证书",
          });
          return;
        }
        this.gspBookPhoto =
          // @ts-ignore
          await this.$refs.gspBookPhotoFiles.doUpload();
      }
      if(this.fileArr.indexOf('10') != -1){
        //开票资料 10
        if (!this.billingPhotoFiles.length) {
          this.$message.show({
            message: "请先上传开票资料",
          });
          return;
        }
        this.billingPhoto =
          // @ts-ignore
          await this.$refs.billingPhotoFiles.doUpload();
      }
      if(this.fileArr.indexOf('11') != -1){
        //药品生产许可证 11
        if (!this.yaopinMakePhotoFiles.length) {
          this.$message.show({
            message: "请先上传药品生产许可证",
          });
          return;
        }
        this.yaopinMakePhoto =
          // @ts-ignore
          await this.$refs.yaopinMakePhotoFiles.doUpload();
      }

      let memberAuth_ty = await memberAuth({
        ...preveinfo,
        businessLicensePhoto: this.dealUrl(this.businessLicensePhoto),
        medicalInstitutionLicensePhoto: this.dealUrl(this.medicalInstitutionLicensePhoto),
        // idCardPhoto: this.dealUrl(this.idCardPhoto),
        // drugBusinessLicensePhoto: this.dealUrl(this.drugBusinessLicensePhoto),
        // drugBusinessLicenseCommunityDescPhoto: this.dealUrl( this.drugBusinessLicenseCommunityDescPhoto),
        farenBookPhoto: this.dealUrl(this.farenBookPhoto),
        weituoCardPhoto: this.dealUrl(this.weituoCardPhoto),
        jigouCardPhoto: this.dealUrl(this.jigouCardPhoto),
        shiyeFarenBookPhoto: this.dealUrl(this.shiyeFarenBookPhoto),
        minbanNoBusinessPhoto: this.dealUrl(this.minbanNoBusinessPhoto),
        yaopinManagePhoto: this.dealUrl(this.yaopinManagePhoto),
        gspBookPhoto: this.dealUrl(this.gspBookPhoto),
        billingPhoto: this.dealUrl(this.billingPhoto),
        yaopinMakePhoto: this.dealUrl(this.yaopinMakePhoto),

      });

      this.$router.push("./personal");
      this.$message.show({ message: "提交成功" });
      // console.log(memberAuth_ty);
    },

    dealUrl(url) {
      if(url){
        return url
        .split(",")
        .map((item) => this.baseUrl+ '/' + item)
        .toString();
      }
      
    },

    //打开客服
    toKefu() {
      const kfUrl = localStorage.getItem("kfUrl") as string;
      window.open(kfUrl, "_blank"); // 新窗口打开外链接
    },
  },
});
