
import { Goods } from "@/api/zmkm";
import { RGoods, searchGoods, getCategories } from "@/api/zmkm-api";
import { groupBy } from "@/utils/array.utils";
import { Categories } from "@/api/zmkm";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchResultView",
  components: { Pager },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      goodsList: [] as RGoods[],
      keywords: "",

      categoriesFunctionId: "" as any,
      categoriesShapeId: "" as any,
      categoriesPropertiesId: "" as any,
      selectedCategory: [],
      spell: "",
      spellList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ] as any,
      categories: {} as Record<number, Categories[]>,
    };
  },
  watch: {
    "$route.params.keywords"(newValue: string) {
      this.keywords = newValue;
      this.searchGoods();
      // location.reload();
      this.getCategories()
    },
    page() {
      this.searchGoods();
    },
    $route(to,from) {
      console.log(to)
      if(to.path == "/goodscategory/categoriesPropertiesId/50"){
        location.reload();
      }
      if(to.path == "/goodscategory/categoriesPropertiesId/51"){
        location.reload();
      }
      if(to.path == "/goodscategory/categoriesPropertiesId/52"){
        location.reload();
      }
    },
  },

  created() {
    this.spellList = this.spellList.map((item) => {
      return { name: item, status: false };
    });
  },
  async mounted() {
    this.keywords = this.$route.params.keywords as string;
    await this.getCategories();
    await this.searchGoods();
  },
  methods: {
    async searchGoods() {
      const goodsList = await searchGoods({
        keywords: this.keywords?.trim(),

        categoriesFunctionId: this.categoriesFunctionId,
        categoriesShapeId: this.categoriesShapeId,
        categoriesPropertiesId: this.categoriesPropertiesId,
        spell: this.spell,
        size: 12,
        page: this.page,
      });
      this.pager = goodsList;
      this.goodsList = goodsList.list;
    },

    navCategory(type, id) {
      if (type == "spell") {
        this.spellList.forEach((item) => {
          if (item.name == id) {
            item["status"] = true as any;
            this.spell = item.name;
          } else {
            item["status"] = false as any;
          }
        });
      }

      if (type == "categoriesFunctionId") {
        this.categories[0].forEach((item) => {
          if (item.id == id) {
            item["status"] = true as any;
            this.categoriesFunctionId = id;
          } else {
            item["status"] = false as any;
          }
        });
      }

      if (type == "categoriesShapeId") {
        this.categories[1].forEach((item) => {
          if (item.id == id) {
            item["status"] = true as any;
            this.categoriesShapeId = id;
          } else {
            item["status"] = false as any;
          }
        });
      }

      if (type == "categoriesPropertiesId") {
        this.categories[2].forEach((item) => {
          if (item.id == id) {
            item["status"] = true as any;
            this.categoriesPropertiesId = id;
          } else {
            item["status"] = false as any;
          }
        });
      }

      this.searchGoods();

      console.log(type, id, "11111111");
    },

    removeCategory(type, id) {
      if (type == "all") {
        this.spellList.forEach((item) => {
          item["status"] = false as any;
        });
        this.spell = "";

        this.categories[0].forEach((item) => {
          item["status"] = false as any;
        });
        this.categoriesFunctionId = "";

        this.categories[1].forEach((item) => {
          item["status"] = false as any;
        });
        this.categoriesShapeId = "";

        this.categories[2].forEach((item) => {
          item["status"] = false as any;
        });
        this.categoriesPropertiesId = "";
      }

      if (type == "spell") {
        this.spellList.forEach((item) => {
          if (item.name == id) {
            item["status"] = false as any;
            this.spell = "";
          }
        });
      }

      if (type == "categoriesFunctionId") {
        this.categories[0].forEach((item) => {
          if (item.id == id) {
            item["status"] = false as any;
            this.categoriesFunctionId = "";
          }
        });
      }

      if (type == "categoriesShapeId") {
        this.categories[1].forEach((item) => {
          if (item.id == id) {
            item["status"] = false as any;
            this.categoriesShapeId = "";
          }
        });
      }

      if (type == "categoriesPropertiesId") {
        this.categories[2].forEach((item) => {
          if (item.id == id) {
            item["status"] = false as any;
            this.categoriesPropertiesId = "";
          }
        });
      }
      this.$router.push({
        name: "goodscategory",
        params: { name: "index", value: "all" },
      });
      this.searchGoods();
      console.log(type, id, "11111111");
    },

    async getCategories() {
      this.categories = groupBy(await getCategories(), (item) => item.type);
      console.log(this.categories, "this.categories");
      let name = this.$route.params.name as string;
      let value = this.$route.params.value as string;

      if (name == "spell") {
        this.spellList.forEach((item) => {
          if (item.name == value) {
            item["status"] = true as any;
            this.spell = value;
          } else {
            item["status"] = false as any;
          }
        });
      }

      if (name == "categoriesFunctionId") {
        this.categories[0].forEach((item) => {
          if (item.id == parseInt(value)) {
            item["status"] = true as any;
            this.categoriesFunctionId = parseInt(value);
          } else {
            item["status"] = false as any;
          }
        });
      }
      if (name == "categoriesShapeId") {
        this.categories[1].forEach((item) => {
          if (item.id == parseInt(value)) {
            item["status"] = true as any;
            this.categoriesShapeId = parseInt(value);
          } else {
            item["status"] = false as any;
          }
        });
      }

      if (name == "categoriesPropertiesId") {
        this.categories[2].forEach((item) => {
          if (item.id == parseInt(value)) {
            item["status"] = true as any;
            this.categoriesPropertiesId = parseInt(value);
          } else {
            item["status"] = false as any;
          }
        });
      }

      console.log(
        name,
        value,
        "------------------111111111111------------------"
      );
    },

    onDetail(goods: Goods) {
      this.$router.push({ name: "goodsdetail", params: { id: goods.id } });
    },
  },
});
