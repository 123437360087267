
import { ExpressAddress } from "@/api/zmkm";
import {
  ApiResponseError,
  addExpressAddress,
  createOrder,
  deleteExpressAddress,
  getExpressAddresses,
  RMemberCoupon,
  ROrderPrepareCreate,
  updateExpressAddress,
} from "@/api/zmkm-api";
import { areaIdToString, provinces } from "@/utils/area.utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettlementView",
  components: {},
  data: () => {
    return {
      warnDisable: false,
      warnMessage: "",
      editAddress: {
        name: "",
        tel: "",
        province: 0,
        city: 0,
        district: 0,
        address: "",
        statusDefault: 1,
      } as ExpressAddress,
      editAddressIsNew: true,
      isAddressShow: false,
      isAddressEnable: true,
      provinces: provinces,
      areas: [0, 0, 0],

      expressAddresses: [] as ExpressAddress[],
      orderDetail: {
        items: [] as any,
        coupons: [] as any,
      } as ROrderPrepareCreate,
      address: undefined as ExpressAddress | undefined,
      coupon: undefined as RMemberCoupon | undefined,
      desc: "",
      expressAddress: undefined as ExpressAddress | undefined,
      addressListShow: false,
      deleteSureVisible: false,
      deleteAddressId: -1,
    };
  },
  mounted() {
    this.init();
    // console.log("=98987=");
    // console.log(this.orderDetail);
  },
  methods: {
    async init() {
      try {
        this.orderDetail = {
          items: [] as any,
          coupons: [] as any,
        } as ROrderPrepareCreate;

        await this.getAddresses();
        this.orderDetail = (await createOrder({
          items: this.$store.state.selectedGoods,
          prepareOnly: true,
          expressAddressId: this.address?.id as number,
        })) as ROrderPrepareCreate;
        console.log("-999-")
        console.log(this.$store.state.selectedGoods);
        console.log(this.orderDetail);

        this.orderDetail.coupons.map((value) => {
          value.effectiveTimeBeg = value.effectiveTimeBeg?.slice(0, 10);
          value.effectiveTimeEnd = value.effectiveTimeEnd?.slice(0, 10);
          return value;
        });
        this.coupon = this.orderDetail.coupons[0];
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.jumpWarn(e.response.message);
        }
      }
    },

    async getAddresses() {
      this.expressAddresses = (await getExpressAddresses()) as ExpressAddress[];
      if (!this.address) {
        console.log(this.address, "----------");

        this.address = this.expressAddresses.find(
          (address) => address.statusDefault === 1
        );
        if (!this.address) {
          this.address = this.expressAddresses[0];
        }
      }
    },
    addressToAreaString(address: ExpressAddress) {
      return [
        areaIdToString(address.province, 1),
        areaIdToString(address.city, 2),
        areaIdToString(address.district, 3),
        address.address,
      ].join("");
    },
    onAreaSelect(code: string, level: 1 | 2 | 3) {
      if (level === 1) {
        this.areas[0] = provinces.findIndex(
          (province) => province.code === code
        );
        this.areas[1] = 0;
      } else if (level === 2) {
        this.areas[1] = provinces[this.areas[0]].children.findIndex(
          (city) => city.code === code
        );
        this.areas[2] = 0;
      }
    },
    async createOrder() {
      try {
        // console.log(this.$store.state.selectedGoods);
        // return false;
        const orderId = await createOrder({
          items: this.$store.state.selectedGoods,
          desc: this.desc,
          memberCouponId: this.coupon?.id,
          expressAddressId: this.address?.id,
          prepareOnly: false,
          from: 1,
        });
        console.log(orderId);
        this.$router.push({
          name: "cashier",
          params: { orderId: orderId as number },
        });
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.jumpWarn("订单获取失败，请重新下单");
          if (!this.address) {
            this.jumpWarn("请选择地址");
          }
        }
      }
    },
    onAddOrEditAddress(address?: ExpressAddress) {
      this.isAddressShow = true;
      this.editAddressIsNew = !address;
      this.editAddress =
        address ||
        ({
          name: "",
          tel: "",
          province: 0,
          city: 0,
          district: 0,
          address: "",
          statusDefault: 1,
        } as ExpressAddress);
      let province = provinces.findIndex(
        (province) => province.code === `${this.editAddress.province}`
      );
      if (province === -1) {
        province = 0;
      }
      let city =
        province === -1
          ? 0
          : provinces[province].children.findIndex(
              (city) => city.code === `${this.editAddress.city}`
            );
      if (city === -1) {
        city = 0;
      }
      let country =
        city === -1
          ? 0
          : provinces[province].children[city].children.findIndex(
              (country) => country.code === `${this.editAddress.district}`
            );
      if (country === -1) {
        country = 0;
      }
      this.areas = [province, city, country];
      if (!address) {
        this.editAddress.province = parseInt(provinces[province].code);
        this.editAddress.city = parseInt(
          provinces[province].children[city].code
        );
        this.editAddress.district = parseInt(
          provinces[province].children[city].children[country].code
        );
      }
    },
    async onSaveAddress() {
      if (this.editAddress.name == "") {
        this.$message.show({ message: "请输入收货人名称" });
        return;
      }
      if (this.editAddress.tel == "") {
        this.$message.show({ message: "请输入收货人手机号" });
        return;
      }
      if (this.editAddress.address == "" || !this.editAddress.address) {
        this.$message.show({ message: "请输入详细地址信息" });
        return;
      }
      if (!this.editAddress.id) {
        await addExpressAddress(this.editAddress);
      } else {
        await updateExpressAddress(this.editAddress.id, this.editAddress);
      }
      this.$message.show({
        message: `${this.editAddressIsNew ? "添加" : "保存"}成功`,
      });
      //await this.getAddresses();
      await this.init();
      this.isAddressShow = false;
    },

    sureDeleteAddress(address) {
      this.deleteAddressId = address.id;
      this.deleteSureVisible = true;
    },

    async deleteAddress() {
      await deleteExpressAddress(this.deleteAddressId);
      this.deleteSureVisible = false;
      this.$message.show({
        message: "删除成功",
      });
      //await this.getAddresses();
      await this.init();
    },

    async selectedAddress(address: ExpressAddress) {
      this.address = address;
      await this.init();
    },

    async defaultAddress(address: ExpressAddress) {
      await updateExpressAddress(address.id, { statusDefault: 1 });
      this.$message.show({
        message: "设置成功",
      });
      await this.getAddresses();
      //await this.init();
    },

    jumpWarn(title: any) {
      this.warnMessage = title;
      this.warnDisable = true;
      setTimeout(() => {
        this.warnDisable = false;
      }, 4000);
    },
  },
});
