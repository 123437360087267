import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25475501"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "uploader" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["accept", "onChange"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (file, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({
        item: true,
        border: true,
        mini: _ctx.size === 'mini',
        middle: _ctx.size === 'middle',
        rectangle: _ctx.size === 'rectangle',
        pointer: true,
      }),
        onClick: ($event: any) => (_ctx.onClick(index))
      }, [
        _createElementVNode("img", {
          src: file.url,
          alt: "",
          title: "点击重新选择文件"
        }, null, 8, _hoisted_3),
        _createElementVNode("img", {
          onClick: _withModifiers(($event: any) => (_ctx.onClose(index)), ["stop"]),
          src: "image/upload-close-icon.png",
          class: "close",
          alt: "",
          title: "点击删除文件"
        }, null, 8, _hoisted_4),
        _createElementVNode("input", {
          ref_for: true,
          ref: `input${index}`,
          type: "file",
          accept: _ctx.accept,
          onChange: ($event: any) => (_ctx.onSelectFile(index, $event.target.files[0]))
        }, null, 40, _hoisted_5)
      ], 10, _hoisted_2))
    }), 128)),
    (_ctx.modelValue.length < _ctx.maxCount)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({
        item: true,
        mini: _ctx.size === 'mini',
        middle: _ctx.size === 'middle',
        rectangle: _ctx.size === 'rectangle',
        pointer: true,
      }),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
        }, [
          _createElementVNode("img", {
            src: 
          _ctx.size === 'rectangle'
            ? 'image/upload-icon.png'
            : 'image/auth-upload-icon.png'
        ,
            alt: ""
          }, null, 8, _hoisted_6),
          (_openBlock(), _createElementBlock("input", {
            key: _ctx.modelValue.length,
            ref: "input",
            type: "file",
            accept: _ctx.accept,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectFile(-1, $event.target.files[0])))
          }, null, 40, _hoisted_7))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}