export function assignWithoutUndefined(
  target: Record<string, any> | undefined,
  source: Record<string, any>
) {
  return Object.assign(
    target || {},
    Object.keys(source).reduce((prev, cur) => {
      const value = source[cur];
      if (value !== undefined) {
        prev[cur] = value;
      }
      return prev;
    }, {} as any)
  );
}
