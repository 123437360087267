import { Directive } from "@vue/runtime-core";

export const clickOutside: Directive = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = (event: Event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
        event.stopPropagation();
      }
    };
    document.addEventListener("mousedown", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("mousedown", el.clickOutsideEvent);
  },
};
