import { areas } from "@/assets/areas";

interface AreaItem {
  code: string;
  name: string;
  children: AreaItem[];
}

export function areaIdToString(id: number, level: 1 | 2 | 3) {
  return (
    [areas.province_list, areas.city_list, areas.county_list][level - 1] as any
  )?.[id];
}

export const provinces: AreaItem[] = Object.entries(areas.province_list).map(
  ([code, name]) => ({ code, name, children: [] as AreaItem[] })
);

export const cities: AreaItem[] = Object.entries(areas.city_list).map(
  ([code, name]) => ({ code, name, children: [] as AreaItem[] })
);

export const counties: AreaItem[] = Object.entries(areas.county_list).map(
  ([code, name]) => ({ code, name, children: [] as AreaItem[] })
);

provinces.forEach((province) => {
  province.children = cities
    .filter((city) => city.code.startsWith(province.code.slice(0, 2)))
    .map((city) => {
      city.children = counties.filter((county) =>
        county.code.startsWith(city.code.slice(0, 4))
      );
      return city;
    });
});
