import { Cfg } from "@/api/zmkm";
import {
  getCfgBase,
  RMemberInfo,
  getMemberInfo,
  SelectedGoods,
  setToken,
  baseUrl,
  getMessages,
  getCartGoods
} from "@/api/zmkm-api";
import { createStore } from "vuex";

const UserInfoKey = "__zmi";

export default createStore({
  state: {
    isMaskShow: false,
    cfg: {} as Cfg,
    memberInfo: undefined as RMemberInfo | undefined,
    selectedGoods: [] as SelectedGoods[],
    talkId: "" as string,
    baseUrl: "" as string,
    messageNum: 0 as number,
    cartGoodsNum: 0 as number
  },
  getters: {},
  mutations: {
    setTalk: (state, talkId) => {
      state.talkId = talkId;
    },
    setBaseUrl: (state, url) => {
      state.baseUrl = url;
    },

    showMask: (state) => {
      state.isMaskShow = true;
    },
    hideMask: (state) => {
      state.isMaskShow = false;
    },
    setIsMaskShow: (state, payload: boolean) => {
      state.isMaskShow = payload;
    },
    setCfg(state, cfg: Cfg) {
      state.cfg = cfg;
    },

    setCartNum(state, cartGoodsNum: number) {
      state.cartGoodsNum = cartGoodsNum;
    },

    setMemberInfo(state, memberInfo?: RMemberInfo) {
      if (memberInfo) {
        state.memberInfo = {
          token: memberInfo.token || state.memberInfo?.token || "",
          memberAccount: memberInfo.memberAccount,
        };
      } else {
        state.memberInfo = undefined;
      }
      if (memberInfo) {
        if (!memberInfo.token) {
          memberInfo.token = state.memberInfo?.token as string;
        }

        localStorage.setItem(UserInfoKey, JSON.stringify(memberInfo));
      } else {
        localStorage.removeItem(UserInfoKey);
      }
      if (state.memberInfo?.token) {
        setToken(state.memberInfo?.token);
      }
    },
    //设置消息数量
    setMessageNum(state, num) {
      state.messageNum = num;
    },
    setSelectedGoods(state, selectedGoods: SelectedGoods[]) {
      state.selectedGoods = selectedGoods;
    },
  },
  actions: {
    async init(context) {
      try {
        const userInfoData = localStorage.getItem(UserInfoKey);
        if (userInfoData) {
          context.commit("setMemberInfo", JSON.parse(userInfoData));
        }
      } catch (e) { }
      context.commit("setBaseUrl", baseUrl);
      context.dispatch("getCfg");

      context.dispatch("getCartNum");

    },

    async getMessagesList(context) {
      const messageList = (await getMessages({
        status: 0,
      })) as any;

      console.log("messageList-------1111--", messageList);
      //if(messageList.total > 0){
      context.commit("setMessageNum", messageList.total);
      //}
    },

    async getCfg(context) {
      const cfg = await getCfgBase();
      context.commit("setCfg", cfg);
    },

    async getCartNum(context) {
      const cartItems = await getCartGoods();
      let cartGoodsNum = 0;
      cartItems.forEach((item) => {
        if (item.goods && item.goodsSpecs) {
          if (item.goodsSpecs.num < 1 || item.goods.status === 0) {
          } else {
            cartGoodsNum++
          }
        }
      });

      console.log(cartGoodsNum,'cartGoodsNum----');
      context.commit("setCartNum", cartGoodsNum);



    },



  },
  modules: {},
});
