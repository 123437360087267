import Message from "./Message.vue";
import { App, createApp } from "vue";

export interface MessageOptions {
  visible?: boolean;
  message: string;
  duration?: number;
  onHided?: () => void;
}

const message = createApp(Message).mount(document.createElement("div"));
function show(options: Pick<MessageOptions, "message">) {
  document.body.appendChild(message.$el);
  // @ts-ignore
  message.show(options);
}

function hide() {
  // @ts-ignore
  message.hide();
}

export interface MessageInstance {
  show: (options: Pick<MessageOptions, "message">) => void;
  hide: () => void;
}

export default {
  install(app: App<Element>) {
    app.config.globalProperties.$message = {
      show,
      hide,
    } as MessageInstance;
  },
};
