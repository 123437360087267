
import { Goods } from "@/api/zmkm";
import byoYomi from "@/components/ByoYomi.vue";
import gsap from "gsap";
import {
  addCartGoods,
  getAdScrollBanners,
  getGoodsSpecsList,
  recommendGoods,
  searchGoods,
} from "@/api/zmkm-api";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IndexView",
  props: {},
  data() {
    return {
      goods: [] as Goods[],
      weekPreferenceGoods: [] as Goods[],
      wholeSaleAreaGoods: [] as Goods[],
      hotSaleGoods: [] as Goods[],
      newsGoods: [] as Goods[],
      recommendGoods: [] as Goods[],
      n: 0, //默认图片是第一张开始
      imgArray: [] as any,
      timer: {},
      pifa: null,
      moveNum: 0,
    };
  },
  components: {
    byoYomi,
    gsap,
  },
  async mounted() {
    await this.refresh();
    await this.getBanner();
    this.play();
  },
  methods: {
    moveRight() {
      const le3 = this.moveNum + 1;
      const le1 = this.wholeSaleAreaGoods.length;
      const le2 = le3 + 4;
      if (le2 <= le1) {
        this.moveNum = this.moveNum + 1;
        let xx = -this.moveNum * 250;
        gsap.to(".move-pifa", {
          duration: 1,
          x: xx,
        });
      }
    },
    moveLeft() {
      if (this.moveNum > 0) {
        this.moveNum = this.moveNum - 1;
        let xx = -this.moveNum * 250;
        gsap.to(".move-pifa", {
          duration: 1,
          x: xx,
        });
      }
    },
    async getBanner() {
      const adScrollBanners = await getAdScrollBanners(1);
      this.imgArray = adScrollBanners; //.map((asb) => asb.image);
    },

    bannerJump(item) {
      console.log(item);
      if (item.urlType == 0) {
        this.$router.push(item.pcUrl);
      } else if (item.urlType == 1) {
        window.open(item.url, "_blank");
      }
    },

    play() {
      this.timer = setInterval(this.autoPlay, 5000); //用定时器控制每张图的显示时间
    },
    autoPlay() {
      this.$data.n++;
      if (this.imgArray.length == this.n) {
        this.n = 0;
      }
    },
    clickImg(index: number) {
      this.n = index; //显示当前点击的图片
    },
    prevImg() {
      if (this.n == 0) {
        this.n = this.imgArray.length - 1;
      } else {
        this.n--;
      }
    },

    nextImg() {
      if (this.n == this.imgArray.length - 1) {
        this.n = 0;
      } else {
        this.n++;
      }
    },

    async refresh() {
      const batchGoods = await Promise.all([
        searchGoods({
          isInWeekPreference: true,
          size: 5,
        }),
        searchGoods({
          isInWholesaleArea: true,
          // size: 7,
        }),
        searchGoods({
          inHotSale: true,
          size: 5,
        }),
        recommendGoods({
          size: 4,
        }),
        searchGoods({
          inNews: true,
          size: 4,
        }),
      ]);
      console.log("==第一个值")
      console.log(batchGoods[0].list);
      // console.log("==第二个值")
      // console.log(batchGoods[1].list);

      batchGoods[0].list.forEach((item,index,arr) => {
        item.priceMin = item.price;
      })
      batchGoods[1].list.forEach((item,index,arr) => {
        item.priceMin = item.price;
      })
      this.weekPreferenceGoods = batchGoods[0].list;
      this.wholeSaleAreaGoods = batchGoods[1].list;
      this.hotSaleGoods = batchGoods[2].list;
      this.recommendGoods = batchGoods[3].list;
      this.newsGoods = batchGoods[4].list;
    },
    onDetail(goods: Goods) {
      var is_youhui = 'false';

      if(goods.weekPreferenceId){
        is_youhui = 'week';
        this.$router.push({
          name: "goodsdetailtwo",
          params: {
            id: goods.id,
            is_youhui:is_youhui,
            common_id:goods.weekPreferenceId
          },
        })
      }else if(goods.wholesaleAreaId){
        is_youhui = 'pifa';
        this.$router.push({
          name: "goodsdetailtwo",
          params: {
            id: goods.id,
            is_youhui:is_youhui,
            common_id:goods.wholesaleAreaId
          },
        })
      }else{
        this.$router.push({
          name: "goodsdetail",
          params: {
            id: goods.id,
          },
        });
      }
    },
    async addCartGoods(goods: Goods) {
      const goodsSpecsList = await getGoodsSpecsList(goods.id);
      if (goodsSpecsList.length) {
        await addCartGoods([
          {
            goodsId: goods.id,
            goodsSpecsMixDescId: goodsSpecsList.sort(
              (a, b) => a.price - b.price
            )[0].id,
            num: 1,
            isYouhui:'',
            commonId:''
          },
        ]);
      }
    },
  },

  beforeUnmount() {
    //clearInterval(this.timer); //清除定时器
  },
});
