import { ApiResponse, ApiResponsePageData } from "@/api/api-response";
import {
  AdScrollBanner,
  Authentication,
  Cart,
  Categories,
  Cfg,
  Coupon,
  ExpressAddress,
  Feedback,
  FirmBank,
  Goods,
  GoodsComment,
  GoodsSpecsMixDesc,
  Invoice,
  LogisticsCo,
  MemberAccount,
  MemberAuth,
  MemberCoupon,
  MessagePush,
  MessagePushMember,
  Orders,
  OrdersAfterSale,
  OrdersAfterSaleFinish,
  OrdersAfterSaleGoods,
  OrdersDesc,
  OrdersPayment,
  OrdersReceiver,
  PriceList,
  Reason,
  SearchRecommend,
} from "@/api/zmkm";
import { buildURLQuery } from "@/utils/url.utils";

export interface RGoods extends Goods {
  specsList?: GoodsSpecsMixDesc[];
}

export interface RPriceList extends PriceList {
  firstGoods: RGoods;
}

export interface RMemberAccount extends MemberAccount {
  level: string;
  authStatus?: number;
  authFirmType?: number;
}

export interface RMemberInfo {
  token: string;
  memberAccount: RMemberAccount;
}

export interface RCartItem extends Cart {
  goods: RGoods;
  goodsSpecs: GoodsSpecsMixDesc;
  goodsSpecsList: GoodsSpecsMixDesc[];
}

export interface GoodsDetail extends Goods {
  expressFee: number;
  commentCount: number;
  taxRate: number;
  specsList: GoodsSpecsMixDesc[];
  imageRotations: string[];
}

export interface RGoodsComment extends GoodsComment {
  nickname: string;
  avatar: string;
  tel: string;
  descImages: string[];
}

export interface ROrderPrepareCreate {
  items: {
    goods: Goods;
    goodsSpecsMixDesc: GoodsSpecsMixDesc;
    num: number;
  }[];
  expressFee: number;
  defaultExpressAddress: ExpressAddress;
  coupons: RMemberCoupon[];
}

interface Page {
  page?: number;
  size?: number;
}

interface RequestOptions {
  url: string;
  method?: "get" | "post" | "delete";
  headers?: Record<string, string>;
  params?: Record<string, any>;
  data?: Record<string, any> | string;
}

//const baseUrl =
//  process.env.ZMKM_MP_API_BASE_URL ||
//  "http://zhimakaimen_miniapp_api.longlaimall.com";
//const baseUrl = "http://localhost:8080";
// export const baseUrl = "https://h5.zmkmzy.com";
// export const baseUrl = "http://localhost:8020";
export const baseUrl = "https://h5.doing365.com";

let token: string | undefined;

export function setToken(t?: string) {
  token = t;
}

export function fixUrl(url?: string | null) {
  if (url && !url.startsWith("http")) {
    return `${baseUrl}/${url}`;
  }
  return url;
}

export class ApiResponseError<T> extends Error {
  constructor(public response: ApiResponse<T>) {
    super();
  }
}

async function request<R>(options: RequestOptions) {
  const { url, data } = options;
  const method = options.method || "get";
  if (method === "get" && options.params) {
    if (
      options.params.page !== undefined &&
      options.params.size === undefined
    ) {
      if(options.url == "price_list") {
        options.params.size = 9;
      } else if(options.url == "addresses"){
        options.params.size = 6;
      } else {
        options.params.size = 12;
      }
    }
  }
  const params = options.params ? buildURLQuery(options.params) : "";
  const urlPrefix = url.startsWith("http") ? "" : `${baseUrl}/`;
  const urlParamsPrefix = url.indexOf("?") > 0 ? "&" : "?";
  const relUrl = `${urlPrefix}${url}${
    params ? `${urlParamsPrefix}${params}` : ""
  }`;

  const headers: HeadersInit = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (!(method === "post" && data instanceof FormData)) {
    headers["Content-Type"] =
      method === "get"
        ? "application/x-www-form-urlencoded"
        : "application/json";
  }
  const response = await fetch(relUrl, {
    method: method || "get",
    body: data
      ? data instanceof FormData
        ? data
        : typeof data === "string"
        ? data
        : JSON.stringify(data)
      : undefined,
    headers,
  });
  const apiResponse = (await response.json()) as ApiResponse<R>;

    //console.log(apiResponse,typeof apiResponse.code,'-----apiResponse.code');

  if (apiResponse.code === 0) {
    return apiResponse.data as R;
  }

  // if (typeof apiResponse.code === 'undefined') {
  //   return apiResponse as unknown;
  // }


  throw new ApiResponseError(apiResponse);
}

export function priceListSearch(
  params?: {
    title?: string;
    goodsTitle?: string;
  } & Page
) {
  return request<ApiResponsePageData<RPriceList>>({
    url: "price_list",
    params,
  });
}

export function getCategories(params?: { type?: number }) {
  return request<Categories[]>({
    url: "categories",
    params,
  });
}

function normalizeGoods(goods?: Goods) {
  if (goods) {
    goods.image = fixUrl(goods.image);
  }
}

function normalizeGoodsComment(goodsComment?: RGoodsComment) {
  if (goodsComment) {
    goodsComment.avatar = fixUrl(goodsComment.avatar) as string;
    goodsComment.descImages = goodsComment.descImage
      ? goodsComment.descImage.split(",").map((url) => fixUrl(url) as string)
      : [];
  }
}

export async function searchGoods(
  params?: {
    categoriesFunctionId?: number;
    categoriesShapeId?: number;
    categoriesPropertiesId?: number;
    keywords?: string;
    spell?: string;
    priceListId?: number;
    getSpecsList?: boolean;
    isInWholesaleArea?: boolean;
    isInWeekPreference?: boolean;
    inNews?: boolean;
    inHotSale?: boolean;
  } & Page
) {
  const goodsWithPage = await request<ApiResponsePageData<RGoods>>({
    url: "goods/search",
    params,
  });
  goodsWithPage.list.forEach(normalizeGoods);
  return goodsWithPage;
}

export async function searchGoodsTwo(
  params?: {
    categoriesFunctionId?: number;
    categoriesShapeId?: number;
    categoriesPropertiesId?: number;
    keywords?: string;
    spell?: string;
    priceListId?: number;
    getSpecsList?: boolean;
    isInWholesaleArea?: boolean;
    isInWeekPreference?: boolean;
    inNews?: boolean;
    inHotSale?: boolean;
  } & Page
) {
  const goodsWithPage = await request<ApiResponsePageData<RGoods>>({
    url: "goods/searchtwo",
    params,
  });
  goodsWithPage.list.forEach(normalizeGoods);
  console.log(goodsWithPage)
  return goodsWithPage;
}


export function recommendGoods(params?: Page) {
  return request<ApiResponsePageData<Goods>>({
    url: "goods/recommends",
    params,
  });
}

export async function getGoodsDetail(id: number) {
  const goodsDetail = await request<GoodsDetail>({
    url: `goods/${id}/detail`,
  });
  
  goodsDetail.image = fixUrl(goodsDetail.image);
  goodsDetail.imageRotations = (goodsDetail.imageRotation || "")
    .split(",")
    .filter((url) => !!url)
    .map((url) => fixUrl(url) as string);
  return goodsDetail;
}

export async function getGoodsDetailtwo(id: number,is_youhui:string,common_id:number) {
  const goodsDetail = await request<GoodsDetail>({
    url: `goods/${id}/${is_youhui}/${common_id}/detail`,
  });
  // console.log("++++++");
  // console.log(goodsDetail);
  goodsDetail.image = fixUrl(goodsDetail.image);
  goodsDetail.imageRotations = (goodsDetail.imageRotation || "")
    .split(",")
    .filter((url) => !!url)
    .map((url) => fixUrl(url) as string);
  return goodsDetail;
}

export async function getGoodsSpecsList(id: number) {
  return request<GoodsSpecsMixDesc[]>({
    url: `goods/${id}/specs`,
  });
}

export async function getGoodsComments(id: number, params?: Page) {
  const goodsCommentsWithPage = await request<
    ApiResponsePageData<RGoodsComment>
  >({
    url: `goods/${id}/comments`,
    params,
  });

  goodsCommentsWithPage.list.forEach(normalizeGoodsComment);
  return goodsCommentsWithPage;
}

export function getCfgBase() {
  return request<Cfg>({
    url: "cfg/base",
  });
}

export function getCfgAgreement() {
  return request<string>({
    url: "cfg/agreement",
  });
}

export function getCfgPrivacy() {
  return request<string>({
    url: "cfg/privacy",
  });
}

export function getCfgSaleContract() {
  return request<string>({
    url: "cfg/sale_contract",
  });
}

export function getCfgAboutUs() {
  return request<string>({
    url: "cfg/about_us",
  });
}

export function getCfgLogo() {
  return request<string>({
    url: "cfg/logo",
  });
}

export function getCfgFirmBank() {
  return request<FirmBank>({
    url: "cfg/firm_bank",
  });
}

export function getCfgAuthentication() {
  return request<Authentication>({
    url: "cfg/authentication",
  });
}

export function getSearchRecommends() {
  return request<SearchRecommend[]>({
    url: "goods/search_recommends",
  });
}

export function authPwd(params: {
  tel: string;
  password: string;
  talkId?: number;
}) {
  return request<RMemberInfo>({
    url: "auth/pwd",
    method: "post",
    data: params,
  });
}

export function authRegister(params: {
  tel: string;
  num: number;
  password: string;
  talkId?: number;
}) {
  return request({
    url: "auth/register",
    method: "post",
    data: params,
  });
}

export function authSms(params: { tel: string }) {
  return request({
    url: "auth/sms",
    method: "post",
    data: params,
  });
}

export function authTel(params: { tel: string; num: number; talkId?: number }) {
  return request<RMemberInfo>({
    url: "auth/tel",
    method: "post",
    data: params,
  });
}

export async function getMemberInfo() {
  const memberInfo = await request<RMemberInfo>({
    url: "member/info",
    method: "get",
  });
  memberInfo.memberAccount.avatar = fixUrl(memberInfo.memberAccount.avatar);
  return memberInfo;
}

export function updateMemberInfo(
  memberAccount: Partial<MemberAccount> & {
    num?: number;
    newNum?: number;
  }
) {
  return request({
    url: "member/info",
    method: "post",
    data: memberAccount,
  });
}

export interface RMemberCoupon
  extends MemberCoupon,
    Pick<
      Coupon,
      | "number"
      | "name"
      | "amount"
      | "condition"
      | "effectiveTimeBeg"
      | "effectiveTimeEnd"
      | "desc"
    > {}

export function getMemberCoupons(
  params: {
    type?: number;
    condition?: number;
  } & Page
) {
  return request<ApiResponsePageData<RMemberCoupon>>({
    url: "coupons",
    params,
  });
}

export function getMemberCouponDetail(id: number) {
  return request<RMemberCoupon>({
    url: `coupons/${id}`,
  });
}

export function memberFeedback(
  params: Partial<Pick<Feedback, "name" | "tel" | "desc" | "image">>
) {
  return request({
    url: "member/feedback",
    method: "post",
    data: params,
  });
}

export async function getCartGoods() {
  const cartItems = await request<RCartItem[]>({
    url: "cart",
  });
  // cartItems.forEach((cartItem) => {
  //   cartItem.goods.image = fixUrl(cartItem.goods.image);
  //   cartItem.statusChecked = !!cartItem.statusChecked as any;
  // });
  return cartItems;
}

export interface SelectedGoods {
  goodsId: number;
  goodsSpecsMixDescId: number;
  num: number;
  isYouhui:string;
  commonId:string;
}

export function addCartGoods(items: SelectedGoods[]) {
  return request({
    url: "cart",
    method: "post",
    data: { items },
  });
}

export function deleteCartGoods(cartId: number) {
  return request({
    url: `cart/${cartId}`,
    method: "delete",
  });
}

export function batchDeleteCartGoods(cartIds: number[]) {
  return request({
    url: "cart/batch",
    method: "delete",
    data: cartIds,
  });
}

export function updateCartGoods(
  id: number,
  params: {
    goodsSpecsMixDescId?: number;
    num?: number;
    statusChecked?: number;
  }
) {
  return request({
    url: `cart/${id}`,
    method: "post",
    data: params,
  });
}

export function batchUpdateCartGoods(
  items: {
    cartId: number;
    goodsSpecsMixDescId?: number;
    num?: number;
    statusChecked?: number;
  }[]
) {
  return request({
    url: `cart/batch`,
    method: "post",
    data: {
      items,
    },
  });
}

export function clearCartGoods() {
  return request({
    url: `cart`,
    method: "delete",
  });
}

export function resetCartGoods(
  items: {
    cartId: number;
    goodsSpecsMixDescId?: number;
    num?: number;
    statusChecked?: number;
  }[]
) {
  return request({
    url: `cart`,
    method: "delete",
    data: {
      items,
    },
  });
}

export function getExpressAddresses(params?: Page) {
  return request<ExpressAddress[] | ApiResponsePageData<ExpressAddress>>({
    url: "addresses",
    params,
  });
}

export function updateExpressAddress(
  id: number,
  address: Partial<ExpressAddress>
) {
  return request({
    url: `addresses/${id}`,
    method: "post",
    data: address,
  });
}

export function addExpressAddress(address: ExpressAddress) {
  return request<ExpressAddress>({
    url: "addresses",
    method: "post",
    data: address,
  });
}

export function deleteExpressAddress(id: number) {
  return request<ExpressAddress>({
    url: `addresses/${id}`,
    method: "delete",
  });
}

export function alipayPc(id: number) {
  return request({
    url: `orders/${id}/pay/alipayPc`,
    method: "post",
  });
}

export function getActiveTime(id: number) {
  return request({
    url: 'goods/weekPreference',
    //method: "post",
  });
}


export function wxPc(id: number) {
  return request({
    url: `orders/${id}/pay/pc`,
    method: "post",
  });
}

export function wxScanLogin(params: {
  code?: string;
  mobile?: string;
  num?: string;
  webOpenid?: string;
  //accessToken?:string;
  unionId?: string;
  talkId?: string;
  gender?: number;
  nickname?: string;
  avatar?:string

}) {
  return request({
    url: 'auth/wx',
    method: "post",
    data: params
  });
}

export async function createOrder(params: {
  items?: SelectedGoods[];
  desc?: string;
  memberCouponId?: number;
  prepareOnly?: boolean;
  expressAddressId?: number;
  from?: number;
}) {
  const orderDetail = await request<ROrderPrepareCreate | number>({
    url: "orders",
    method: "post",
    data: params,
  });
  if (typeof orderDetail !== "number" && orderDetail.items) {
    orderDetail.items.forEach((item) => {
      normalizeGoods(item.goods);
    });
  }
  return orderDetail;
}

export function cancelOrder(
  id: number,
  data: {
    reasonId: number;
  }
) {
  return request({
    url: `orders/${id}/cancel`,
    method: "post",
    data,
  });
}

export interface ROrderDesc extends OrdersDesc {
  goods?: RGoods;
  goodsSpecsMixDesc?: GoodsSpecsMixDesc;
  goodsComment?: RGoodsComment;
  ordersReceiver?: OrdersReceiver;
}

export interface ROrder extends Orders {
  descs: ROrderDesc[];
}

function normalizeOrder(order: ROrder) {
  order.descs.forEach((desc) => {
    desc.goodsImage = fixUrl(desc.goodsImage) as string;
    normalizeGoods(desc.goods);
    normalizeGoodsComment(desc.goodsComment);
  });
}

export async function getOrders(
  params: Partial<Pick<Orders, "memberAccountId" | "status">> & {
    keywords?: string;
    isInCommented?: boolean;
    isInNeedComment?: boolean;
  } & Page
) {
  const orders = await request<ApiResponsePageData<ROrder>>({
    url: "orders",
    params,
  });
  orders.list.forEach(normalizeOrder);
  return orders;
}

export interface LogisticsInfo {
  company: string;
  sn: string;
}

export interface ROrderAfterSaleGoods extends OrdersAfterSaleGoods {
  goods: Goods;
  goodsSpecsMixDesc: GoodsSpecsMixDesc;
  price: number;
}

export interface ROrderAfterSale extends OrdersAfterSale {
  items: ROrderAfterSaleGoods[];
  logistics?: LogisticsInfo;
}

export async function getAfterSaleOrders(
  params: {
    goodsName?: string;
  } & Partial<Pick<OrdersAfterSale, "sn" | "type" | "status">> &
    Page
) {
  const orders = await request<ApiResponsePageData<ROrderAfterSale>>({
    url: "after_sales",
    params,
  });
  orders.list.forEach((order) => {
    order.items.forEach((item) => {
      normalizeGoods(item.goods);
    });
  });
  return orders;
}

export function cancelAfterSaleOrder(id: number) {
  return request({
    url: `after_sales/${id}/cancel`,
    method: "post",
  });
}

export interface ROrderDetail extends Orders {
  descs: ROrderDesc[];
  coupon?: RMemberCoupon;
  receiver: OrdersReceiver;
  payment?: OrdersPayment;
  expressTime?: string;
  finishTime?: string;
  reason?: string;
  invoice_apply_status?: Invoice["status"];
}

export async function getOrderDetail(id: number) {
  const order = await request<ROrderDetail>({
    url: `orders/${id}`,
  });
  normalizeOrder(order);
  return order;
}

interface PostAddress {
  address: string;
  name: string;
  phone: string;
}

interface ROrdersAfterSaleDetail extends OrdersAfterSale {
  items: ROrderAfterSaleGoods[];
  reason?: string;
  postAddress?: PostAddress;
  logistics?: LogisticsInfo;
  finish?: OrdersAfterSaleFinish;
}

export async function getAfterSaleDetail(id: number) {
  const orders = await request<ROrdersAfterSaleDetail>({
    url: `after_sales/${id}`,
  });
  orders.items.forEach((order) => normalizeGoods(order.goods));
  return orders;
}

export async function getLogisticsCompanies() {
  return await request<LogisticsCo[]>({
    url: `logistics_cos`,
  });
}

export async function setAfterSaleExpress(id: number, data) {
  return request({
    url: `after_sales/${id}/express`,
    method: "post",
    data,
  });
}

export interface LogisticsDynamic extends LogisticsInfo {
  orderDetail: ROrderDetail;
  dynamics: {
    time: string;
    text: string;
  }[];
}

export function getOrderLogistics(id: number) {
  return request<LogisticsDynamic>({
    url: `orders/${id}/logistics`,
  });
}

export function getAfterOrderLogistics(id: number) {
  return request<LogisticsDynamic>({
    url: `after_sales/${id}/logistics`,
    method: "post",
  });
}



export function getOrderStatus(id: number) {
  return request<Orders["status"]>({
    url: `orders/${id}/status`,
  });
}

export function addOrderComment(
  id: number,
  data: {
    ordersDescId: number;
    descText?: string;
    descImage?: string;
    lever: number;
    match?: number;
    logistics?: number;
    attitude?: number;
  }
) {
  return request({
    url: `orders/${id}/comments`,
    method: "post",
    data,
  });
}

export function confirmOrder(id: number) {
  return request({
    url: `orders/${id}/confirm`,
    method: "post",
  });
}

export function payOrderOffline(
  id: number,
  data: {
    compellation: string;
    bankCard: string;
    payImage: string;
    from: number;
  }
) {
  return request({
    url: `orders/${id}/pay/offline`,
    method: "post",
    data,
  });
}

export function refundOrder(
  id: number,
  data: {
    ordersDescs: {
      id: number;
      num: number;
    }[];
    type: number;
    reasonId?: number;
    price: number;
    desc?: string;
    certificate?: string;
  }
) {
  return request({
    url: `orders/${id}/refund`,
    method: "post",
    data,
  });
}

export function deleteOrder(id: number) {
  return request({
    url: `orders/${id}`,
    method: "delete",
  });
}

export interface RInvoice extends Invoice {
  price: number;
  images: string[];
}

function normalizeInvoice(invoice: RInvoice) {
  if (invoice.image) {
    invoice.images = JSON.parse(invoice.image);
  } else {
    invoice.images = [];
  }
}

export async function getInvoices(
  params: Partial<Pick<Invoice, "status">> & Page
) {
  const invoices = await request<ApiResponsePageData<RInvoice>>({
    url: "invoices",
    params,
  });

  invoices.list.forEach(normalizeInvoice);
  return invoices;
}

export async function getInvoiceDetail(id: number) {
  const invoice = await request<RInvoice>({
    url: `invoices/${id}`,
  });
  normalizeInvoice(invoice);
  return invoice;
}

export function createInvoice(
  data: Pick<
    Invoice,
    | "ordersId"
    | "invoiceType"
    | "riseType"
    | "firmName"
    | "tax"
    | "registeredAddress"
    | "tel"
    | "bankOfDeposit"
    | "bankAccount"
    | "image"
  >
) {
  return request({
    url: "invoices",
    method: "post",
    data,
  });
}

export function uploadFile(file: File) {
  const form = new FormData();
  form.append("file", file);
  return request<string>({
    url: "upload",
    method: "post",
    data: form,
  });
}

export function getReasons() {
  return request<Reason[]>({
    url: "cfg/reasons",
  });
}

export function memberAuth(
  data: Pick<
    MemberAuth,
    "firmName" | "firmType" | "province" | "city" | "district"
  > &
    Partial<
      Omit<
        MemberAuth,
        "firmName" | "firmType" | "province" | "city" | "district"
      >
    >
) {
  return request({
    url: "member/auth",
    method: "post",
    data,
  });
}

export function getMemberAuth() {
  return request<MemberAuth>({
    url: "/member/auth",
  });
}

export function getLogisticsCos() {
  return request<LogisticsCo>({
    url: "logistics_cos",
  });
}

export function addVisitHistory() {
  return request<LogisticsCo>({
    url: "visit_histories",
    method: "post",
  });
}

export async function getAdScrollBanners(type: AdScrollBanner["type"] = 1) {
  const banners = await request<AdScrollBanner[]>({
    url: "ad_scroll_banners",
    params: {
      type,
    },
  });
  banners.forEach((banner) => {
    banner.image = fixUrl(banner.image)!;
  });
  return banners;
}

export type RMessage = MessagePushMember & Pick<MessagePush, "desc">;

export function getMessages(
  params: Partial<Pick<MessagePushMember, "status">> & Page
) {
  return request<ApiResponsePageData<RMessage>>({
    url: "messages",
    params,
  });
}

export function setMessageRead(id: number) {
  return request({
    url: `messages/${id}/read`,
    method: "post",
  });
}

export function setAllMessageRead() {
  return request({
    url: "messages/readall",
    method: "post",
  });
}

export function deleteMessage(id: number) {
  return request({
    url: `messages/${id}`,
    method: "delete",
  });
}

export async function mpGenQRCode(params: {
  path: string;
  envVersion?: "release" | "trial" | "develop";
}) {
  return fixUrl(
    await request<string>({
      url: `mp/qrcode`,
      method: "post",
      data: params,
    })
  );
}
export async function updateUserLevel(id: number) {
  return request<ExpressAddress>({
    url: `member/updateUserLevel/${id}`,
    method: "get",
  });
}

export async function updateAllUserLevel() {
  return request<ExpressAddress>({
    url: `member/updateUserLevel`,
    method: "get",
  });
}

