
import { ApiResponseError, authSms, updateMemberInfo } from "@/api/zmkm-api";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "SafeView",
  data() {
    return {
      tableCurrentIndex: 0,
      tel: "",
      num: "",
      password: "",
      repeatPassword: "",
      newTel: "",
      newNum: "",
      phonenum: "",
      codeStr: "获取验证码",
      codeStatus: false,

      codeNewStr: "获取验证码",
      codeNewStatus: false,

      warnDisable: false,
      warnMessage: "",
    };
  },
  computed: {
    ...mapState(["memberInfo"]),
  },
  mounted() {
    this.tel = this.memberInfo?.memberAccount.tel;
  },
  methods: {
    handleTab(index: number) {
      this.tableCurrentIndex = index;
    },
    async sendSms(tel: string) {
      await authSms({
        tel,
      });
    },
    async onSave() {
      if (this.tableCurrentIndex === 0) {
        if (this.num == "") {
          this.jumpWarn("请输入验证码");
          return;
        }

        if (this.password == "") {
          this.jumpWarn("请输入密码");
          return;
        }
        if (this.repeatPassword == "") {
          this.jumpWarn("请输入确认密码");
          return;
        }

        if (this.password != this.repeatPassword) {
          this.jumpWarn("密码不一致，请重新输入");
          return;
        }
        let reg =
          /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,20}$/;
        if (!reg.test(this.password)) {
          this.jumpWarn("密码格式有误，请按照要求修改");
          return;
        }

        try {
          await updateMemberInfo({
            tel: this.tel,
            num: parseInt(this.num),
            password: this.password,
          });
          this.$message.show({ message: "修改成功,请重新登录！" });

          setTimeout(() => {
            //退出登录
            this.$store.commit("setMemberInfo", undefined);
            localStorage.removeItem("token");
            window.sessionStorage.clear();
            this.$router.push("/");
          }, 1000);
        } catch (e) {
          if (e instanceof ApiResponseError) {
            this.$message.show({ message: e.response.message! });
          }
        }
      } else {
        try {
          if (this.phonenum == "") {
            this.jumpWarn("请输入验证码");
            return;
          }

          if (!this.newTel) {
            this.jumpWarn("请输入新手机号");
            return;
          }

          if (!this.newNum) {
            this.jumpWarn("请输入新手机号的验证码");
            return;
          }

          await updateMemberInfo({
            tel: this.newTel,
            num: parseInt(this.phonenum),
            newNum: parseInt(this.newNum),
          });

          this.$message.show({ message: "修改成功,请重新登录！" });

          setTimeout(() => {
            //退出登录
            this.$store.commit("setMemberInfo", undefined);
            localStorage.removeItem("token");
            window.sessionStorage.clear();
            this.$router.push("/");
          }, 1000);
        } catch (e) {
          if (e instanceof ApiResponseError) {
            this.$message.show({ message: e.response.message! });
          }
        }
      }
    },

    // onSave() {
    //   console.log("你饿吗");
    //   let reg =
    //     /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,20}$/;
    //   if (!reg.test(this.password)) {
    //     this.$message.show({ message: "密码格式不对，请按照要求修改" });
    //   }
    // },

    onSendSms() {
      if (this.tel == "") {
        this.jumpWarn("请输入手机号");
        return;
      }
      if (!/^1\d{10}$/.test(this.tel)) {
        this.jumpWarn("号码格式有误");
        return;
      }
      this.codeStatus = true;

      authSms({
        tel: this.tel,
      }).then((res) => {
        console.log(res, "返回数据");
        this.jumpWarn("验证码发送成功");
        this.countDownTimer();
      });
    },

    // 获取验证码
    countDownTimer: function () {
      //let that = this;
      let second = 60;
      this.codeStr = second + "s后重发";
      let timer = setInterval(() => {
        second -= 1;
        if (second > 0) {
          this.codeStr = second + "s后重发";
        } else {
          clearInterval(timer);
          this.codeStr = "获取验证码";
          this.codeStatus = false;
        }
      }, 1000);
    },

    onSendNewSms() {
      console.log("ddddddd-----", this.newTel);
      if (this.newTel == "") {
        console.log("ssssss");
        this.jumpWarn("请输入新手机号");
        return;
      }

      if (!/^1\d{10}$/.test(this.newTel)) {
        this.jumpWarn("新号码格式有误");
        return;
      }
      this.codeNewStatus = true;

      authSms({
        tel: this.newTel,
      }).then((res) => {
        console.log(res, "返回数据");
        this.jumpWarn("验证码发送成功");
        this.countDownNewTimer();
      });
    },

    // 获取验证码
    countDownNewTimer: function () {
      //let that = this;
      let second = 60;
      this.codeNewStr = second + "s后重发";
      let timer = setInterval(() => {
        second -= 1;
        if (second > 0) {
          this.codeNewStr = second + "s后重发";
        } else {
          clearInterval(timer);
          this.codeNewStr = "获取验证码";
          this.codeNewStatus = false;
        }
      }, 1000);
    },

    jumpWarn(title: any) {
      this.warnMessage = title;
      this.warnDisable = true;
      setTimeout(() => {
        this.warnDisable = false;
      }, 1000);
    },
  },
});
