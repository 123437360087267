
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NumberInput",
  props: {
    modelValue: {
      type: Number as PropType<number>,
      default: () => 0,
    },
    min: {
      type: Number,
      default: () => -Number.MAX_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: () => Number.MAX_SAFE_INTEGER,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "update:modelValue"(value: number) {
      return true;
    },
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  methods: {
    onInput(value: string) {
      let newValue = 0;
      if (value.length) {
        newValue = parseInt(value);
      }
      if (newValue > this.max) {
        newValue = this.max;
        this.isDisabled = true;
      }
      this.$emit("update:modelValue", newValue);
    },
    onAdd() {
      if (this.modelValue < this.max) {
        this.emitUpdate(this.modelValue + 1);
      }
    },
    onSub() {
      if (this.modelValue > this.min) {
        this.emitUpdate(this.modelValue - 1);
      }
    },
    emitUpdate(newValue: number) {
      this.$emit(
        "update:modelValue",
        Math.max(this.min, Math.min(this.max, newValue))
      );
    },
  },
  watch: {
    modelValue(newValue: number) {
      if (newValue < this.min || newValue > this.max) {
        this.emitUpdate(newValue);
      }
      if (this.modelValue <= this.max) {
        this.isDisabled = false;
      }
    },
  },
});
