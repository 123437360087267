import { clickOutside } from "@/components/directives";
import Message from "@/components/Message";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  .use(Message)
  .directive("click-outside", clickOutside)
  .mount("#app");
