
import { GoodsSpecsMixDesc } from "@/api/zmkm";
import {
  addCartGoods,
  getGoodsComments,
  getGoodsDetail,
  getGoodsDetailtwo,
  GoodsDetail,
  RGoodsComment,
  SelectedGoods,
} from "@/api/zmkm-api";

import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
Swiper.use([Pagination, Navigation, Autoplay]);
import "swiper/swiper-bundle.css";
import {
  getCartGoods,
  RCartItem,
} from "@/api/zmkm-api";
import { goToSettlement } from "@/utils/settlement.utils";
import NumberInput from "@/components/NumberInput.vue";
import MirrorImage from "@/components/mirrorimage.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "GoodsDetailView",
  components: { NumberInput,MirrorImage },
  data() {
    return {
      goodsDetail: {} as GoodsDetail,
      goodsComments: [] as RGoodsComment[],
      selectedSpecsId: -1,
      selectedNum: 1,
      isCommentShow: false,
      imageIndex: 0,
      currentImage: "",
      isMaskShow: false,
      warnVisible: false,

      previewVisible: false,
      imageList: [],
      is_youhui:false,
      is_common:0,

      cartItems: [] as RCartItem[],
      invalidCartItems: [] as RCartItem[],

      exit: false
    };
  },
  async mounted() {
    const id = parseInt(this.$route.params.id as string);

    const is_youhui = this.$route.params.is_youhui as string;
    const common_id = parseInt(this.$route.params.common_id as string);
    if(is_youhui){
      this.goodsDetail = await getGoodsDetailtwo(id,is_youhui,common_id);
      let price = this.goodsDetail.priceMin;
      // this.goodsDetail.specsList.forEach((item) => {
      //   item.price = price
      // });
      this.is_youhui = true;
    }else{
      this.goodsDetail = await getGoodsDetail(id);
      this.is_youhui = false;
    }
    console.log("++==++");
      console.log(this.goodsDetail);
    // this.goodsDetail = await getGoodsDetail(id);
    // this.is_youhui = false;
    // console.log(this.goodsDetail);
    //console.log(this.goodsDetail.imageRotations);

    this.currentImage = this.goodsDetail.imageRotations[0];

    this.goodsComments = (await getGoodsComments(id)).list;

    this.goodsComments.forEach((commit) =>{
      
      commit.tel = commit.tel.substr(0, 3) + '****' + commit.tel.substr(7)
      console.log(commit.tel,"tel")
    })

    //console.log(this.goodsComments, "this.goodsComments");

    this.$nextTick(() => {
      let swiperSmall = new Swiper(".swiper-container", {
        slidesPerView: 5,
        navigation: {
          nextEl: ".arrow-right",
          prevEl: ".arrow-left",
        },
      });
    });

    //this.selectedSpecsId = this.goodsDetail.specsList.sort((a, b) => a.price - b.price)[0].id || 0;
  },
  computed: {
    selectedSpecs(): GoodsSpecsMixDesc | undefined {
      console.log("tty")
      //const is_youhui = this.$route.params.is_youhui as string;
      // console.log(this.goodsDetail.specsList);
      let specs_res =  this.goodsDetail?.specsList?.find(
        (specs) => specs.id === this.selectedSpecsId
      );
      let is_c = specs_res?.isCommon;
      if(is_c){
        this.is_common = 1;
      }else{
        this.is_common = 0;
      }
      console.log(this.is_common)
      return specs_res;
    },
    ...mapState(["memberInfo"]),
  },
  methods: {
    previewImage(imageList, index) {
      this.imageList = imageList;
      console.log(this.imageList);
      this.imageIndex = index;
      this.previewVisible = true;
    },

    goProve() {
      this.$router.push("/prove");
    },
    cancelBtn() {
      this.isMaskShow = !this.isMaskShow;
    },
    onBuy() {
      // console.log(this.memberInfo);
      // return false
      if (!this.memberInfo) {
        this.$message.show({ message: "请先登录" });
        return;
      }

      if (this.memberInfo.memberAccount.authStatus == 1) {
      } else if (this.memberInfo.memberAccount.authStatus == -1) {
        this.$message.show({ message: "首营认证审核中" });
        return;
      } else {
        this.isMaskShow = !this.isMaskShow;
        // this.$message.show({ message: "请先去首营认证" });
        return;
      }

      if (this.selectedSpecsId == -1) {
        this.$message.show({ message: "请选择批号~" });
        return;
      }

      if (this.selectedSpecs?.num == 0) {
        this.$message.show({ message: "你选择的商品批号暂时缺货" });
        return;
      } else {

        //const id = parseInt(this.$route.params.id as string);
        let is_youhui = this.$route.params.is_youhui as string;
        let common_id = this.$route.params.common_id as string;

        if(this.is_common == 0){
          is_youhui = "";
          common_id = ""
        }

        const selectGoods = {
          goodsId: this.goodsDetail.id,
          goodsSpecsMixDescId: this.selectedSpecsId,
          num: this.selectedNum,
          isYouhui:is_youhui,
          commonId:common_id,
        } as SelectedGoods;

        goToSettlement([selectGoods]);

        this.$router.push("/settlement");
      }
    },
    async onAddCart() {
      console.log(this.selectedSpecsId);
      if (!this.memberInfo) {
        this.$message.show({ message: "请先登录" });
        return;
      }

      if (this.memberInfo.memberAccount.authStatus == 1) {
      } else if (this.memberInfo.memberAccount.authStatus == -1) {
        this.$message.show({ message: "首营认证审核中" });
        return;
      } else {
        //this.$message.show({ message: "请先去首营认证" });
        this.warnVisible = true;
        return;
      }

      if (this.selectedSpecsId == -1) {
        this.$message.show({ message: "请选择批号~" });
        return;
      }

      if (this.selectedSpecs?.num == 0) {
        this.$message.show({ message: "你选择的商品批号暂时缺货" });
        return;
      } else {
        const cartItems = await getCartGoods();
        console.log("====");
        console.log(cartItems);
        let item =  cartItems?.find(
          (specs) => this.selectedSpecsId === specs.goodsSpecs.id
        ); 
        if(typeof(item) != "undefined" && item!.goodsSpecs.num  < (item!.num + this.selectedNum)) {
          this.$message.show({ message: "库存紧张，最多只能买"+ (item!.goodsSpecs.num - item!.num) +"件"});
        } else {
          let is_youhui = this.$route.params.is_youhui as string;
          let common_id = this.$route.params.common_id as string;

          if(this.is_common == 0){
            is_youhui = "";
            common_id = ""
          };

          await addCartGoods([
            {
              goodsId: this.goodsDetail.id,
              goodsSpecsMixDescId: this.selectedSpecsId,
              num: this.selectedNum,
              isYouhui:is_youhui,
              commonId:common_id
            },
          ]);
          this.$message.show({ message: "购物车添加成功" });
          this.$store.dispatch("getCartNum");
        }
      }
      // if (this.memberInfo.memberAccount.authStatus == 1) {
      // }else if(this.memberInfo.memberAccount.authStatus == -1){
      // 	this.$message.show({ message: "首营认证审核中" });
      // 	return;
      // }else{
      // 	this.isMaskShow = !this.isMaskShow
      // 	// this.$message.show({ message: "请先去首营认证" });
      // 	return;
      // }
      //   await addCartGoods([
      //     {
      //       goodsId: this.goodsDetail.id,
      //       goodsSpecsMixDescId: this.selectedSpecsId,
      //       num: this.selectedNum,
      //     },
      //   ]);
    },

    onAuth() {
      this.warnVisible = false;

      this.$router.push("/prove");
    },

    //打开客服
    toKefu() {
      window.open(localStorage.getItem("kfUrl") as string, "_blank"); // 新窗口打开外链接
    },
  },
});
