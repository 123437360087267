
import {
  deleteCartGoods,
  batchDeleteCartGoods,
  batchUpdateCartGoods,
  getCartGoods,
  RCartItem,
  updateCartGoods,
} from "@/api/zmkm-api";
import CheckBox from "@/components/ChexkBox.vue";
import NumberInput from "@/components/CartNumberInput.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CartView",
  components: { CheckBox, NumberInput },
  data: () => {
    return {
      cartItems: [] as RCartItem[],
      invalidCartItems: [] as RCartItem[],
      isCartItemsAllSelected: false,
      isInvalidCartAllSelected: false,
    };
  },
  mounted() {
    this.getCartList();
    console.log(this.cartItems, "wjq")
  },
  computed: {
    maybeCartItemsAllSelected() {
      return (
        !!this.cartItems.length &&
        this.cartItems.every((cartItem) => cartItem.statusChecked)
      );
    },
    maybeInvalidCartItemsAllSelected() {
      return (
        this.invalidCartItems.length &&
        this.invalidCartItems.every((cartItem) => cartItem.statusChecked)
      );
    },
    selectedCartItems() {
      return this.cartItems.filter((item) => item.statusChecked);
    },
  },
  methods: {
    async getCartList() {
      const cartItems = await getCartGoods();
      console.log("==tang==")
      console.log(cartItems);
      cartItems.forEach((item) => {
        console.log(item.goods);
        if (item.goods && item.goodsSpecs) {
          if (item.goodsSpecs.num < 0 || item.goods.status === 0) {
            this.invalidCartItems.push(item);
          } else {
            item["specShow"] = false;
            this.cartItems.push(item);
          }
        }
        if (item.num >= item.goodsSpecs.num) {
          item.num = item.goodsSpecs.num;
        }
      });
    },

    async selectedSpecs(item, specs) {
      if (specs.num > 0) {
        item.goodsSpecs = { ...specs };
        await updateCartGoods(item.id, {
          goodsSpecsMixDescId: specs.id,
        });
      }
      item["specShow"] = false;
    },

    handleSpecShow(item) {
      this.cartItems.forEach((row) => {
        if (item.id == row.id) {
          row["specShow"] = !item.specShow;
        } else {
          row["specShow"] = false;
        }
      });
    },

    async onSub(item) {
      if (item.num > 1) {
        item.num = item.num - 1;
        await updateCartGoods(item.id, {
          num: item.num,
        });
      }
    },

    onInput(item) {
      console.log(item);
      if (item.num != "" && item.num < 1) {
        console.log("-------22222222--");
        item.num = 1;
        updateCartGoods(item.id, {
          num: item.num,
        });
      } else if (item.num != "" && item.num > item.goodsSpecs.num) {
        console.log("---------");
        item.num = item.goodsSpecs.num;
        updateCartGoods(item.id, {
          num: item.num,
        });
      } else if (item.num != "") {
        console.log("-------11111--");
        updateCartGoods(item.id, {
          num: item.num,
        });
      }
    },

    async onAdd(item) {
      if (item.num < item.goodsSpecs.num) {
        item.num = item.num + 1;
        await updateCartGoods(item.id, {
          num: item.num,
        });
      }
    },

    async onCartItemBothSelectChanged(isValid: boolean, isSelected: boolean) {
      const cartItems = isValid ? this.cartItems : this.invalidCartItems;
      cartItems.forEach((item) => {
        item.statusChecked = isSelected as any;
      });
      await batchUpdateCartGoods(
        cartItems.map((cartItem) => {
          return {
            cartId: cartItem.id,
            statusChecked: isSelected ? 1 : 0,
          };
        })
      );
    },
    async onCartItemSelectChanged(
      isValid: boolean,
      cartItem: RCartItem,
      isSelected: boolean
    ) {
      if (isValid) {
        this.isCartItemsAllSelected = false;
      } else {
        this.isInvalidCartAllSelected = false;
      }
      await updateCartGoods(cartItem.id, {
        statusChecked: isSelected ? 1 : 0,
      });
    },
    async onDelete(valid: boolean) {
      const selectIds = (valid ? this.cartItems : this.invalidCartItems)
        .filter((item) => item.statusChecked)
        .map((item) => item.id);
      if (!selectIds.length) {
        this.$message.show({ message: "请先选择商品" });
        return;
      }
      await batchDeleteCartGoods(selectIds);
      if (valid) {
        this.cartItems = this.cartItems.filter(
          (item) => !selectIds.includes(item.id)
        );
      } else {
        this.invalidCartItems = this.invalidCartItems.filter(
          (item) => !selectIds.includes(item.id)
        );
      }
    },

    async onOneDelete(index,cartId,type){

      await deleteCartGoods(cartId);
      if(type==1){
        this.cartItems.splice(index,1);
      }else{

        // 无效订单删除
        this.invalidCartItems.splice(index,1);
      }

      this.$store.dispatch("getCartNum");
      
    },

    onPrepay() {
      if (this.cartItems.every((item) => !item.statusChecked)) {
        this.$message.show({
          message: "请先选择商品",
        });
        return;
      }

      if (
        this.cartItems.some(
          (item) => item.statusChecked && String(item.num) === ""
        )
      ) {
        this.$message.show({
          message: "请先选择数量",
        });
        return;
      }

      this.$router.push("/settlement");
    },
  },
});
