
import { priceListSearch, RPriceList } from "@/api/zmkm-api";
import { formatTime } from "@/utils/time.utils";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PriceSheetView",
  components: { Pager },
  data: () => {
    return {
      pager: undefined as any,
      page: 1,
      keywords: "",
      key: "",
      priceSheets: [] as RPriceList[],
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    reGetKey(newKey: number, oldKey) {
      if (newKey !== oldKey) {
        this.getList();
      }
    },
  },
  computed: {
    reGetKey() {
      return `${this.page}|${this.key}`;
    },
  },
  methods: {
    async getList() {
      const priceSheets = await priceListSearch({
        page: this.page,
        title: this.keywords,
      });
      priceSheets.list.forEach((priceSheet) => {
        // console.log(priceSheet)
        const { firstGoods } = priceSheet;
        const time = new Date(priceSheet.crtTime);

        (priceSheet as any).desc = `${firstGoods.name}, 规格: ${firstGoods.specs}, 当地: ${firstGoods.placeProduction}`;
        
        (priceSheet as any).year = time.getFullYear();
        (priceSheet as any).monthDay = formatTime(time, "MM.dd");
      });
      this.pager = priceSheets;
      this.priceSheets = priceSheets.list;
    },
    search() {
      this.page = 1;
      this.key = `${Date.now()}-${this.keywords}`;
    },
    onViewDetail(priceSheet: RPriceList) {
      this.$router.push({
        name: "pricesheetdetail",
        params: {
          id: priceSheet.id,
          name: priceSheet.title,
        },
      });
    },
  },
});
