
import { defineComponent } from "vue";

export default defineComponent({
  name: "CheckBox",
  props: {
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    checked: {
      type: Boolean,
      default: () => undefined,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    change(value: boolean) {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "update:modelValue"(value: boolean) {
      return true;
    },
  },
  data() {
    return {};
  },
  computed: {
    isChecked() {
      return this.checked !== undefined ? this.checked : this.modelValue;
    },
  },
  methods: {
    onClick() {
      this.$emit("change", !this.isChecked);
      this.$emit("update:modelValue", !this.isChecked);
    },
  },
});
