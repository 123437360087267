
import { MessageOptions } from "@/components/Message";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Message",
  props: {},
  unmounted() {
    this.stopTimer();
  },
  data() {
    return {
      visible: false,
      message: "",
      duration: undefined as number | undefined,
      timer: 0,
    };
  },
  methods: {
    show(options: Omit<MessageOptions, "visible">) {
      this.visible = true;
      this.message = options.message;
      this.duration = options.duration;
      this.stopTimer();
      if (this.visible) {
        this.startTimer();
      }
    },
    hide() {
      this.visible = false;
      this.stopTimer();
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.visible = false;
        this.timer = 0;
      }, this.duration || 2000);
    },
    stopTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
  },
});
