
import { defineComponent } from "vue";
import CommentList from "./components/CommentList.vue";
export default defineComponent({
  name: "CommentsView",
  components: {
    CommentList,
  },
  data() {
    return {
      tabCurrentIndex: 0,
      tabs: [
        { name: "tab_1", label: "已评价", status: 1, enable: true },
        { name: "tab_2", label: "待评价", status: 0, enable: false },
      ],
    };
  },
  methods: {
    onTabSelect(index: number) {
      this.tabCurrentIndex = index;
      this.tabs[index].enable = true;
    },
  },
});
