
import { defineComponent } from "vue";

import AfterList from "./components/AfterList.vue";

import OrderList from "./components/OrderList.vue";

export default defineComponent({
  name: "orderview",
  components: {
    OrderList,
    AfterList,
  },
  data() {
    return {
      tabCurrentIndex: 0,
      tabs: [
        { name: "tab_1", label: "全部", status: undefined, enable: true },
        { name: "tab_2", label: "待付款", status: 0, enable: false },
        { name: "tab_3", label: "待审核", status: 1, enable: false },
        { name: "tab_4", label: "待发货", status: 2, enable: false },
        { name: "tab_5", label: "待收货", status: 3, enable: false },
        { name: "tab_6", label: "已完成", status: 4, enable: false },
        { name: "tab_7", label: "售后", status: 8, enable: false },
      ],
      reload: {},
    };
  },
  activated() {
    console.log("页面被激活");
    this.reload = {};
  },

  methods: {
    onTabSelect(index: number) {
      this.tabCurrentIndex = index;
      this.tabs[index].enable = true;
    },
  },
});
