
import { getMessages, setAllMessageRead } from "@/api/zmkm-api";
import CountDown from "@/components/CountDown.vue";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageList",
  components: { CountDown, Pager },
  props: {
    statusLabel: {
      type: String,
    },
    status: {
      type: Number,
    },
  },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      messageList: [] as any[],

      // messageList:[
      //   {id:1,content:'sssdd1',flag:false},
      //   {id:2,content:'sssdd2',flag:false},
      //   {id:3,content:'sssdd3',flag:false},

      // ],
      //currentIndex:-1
    };
  },
  async mounted() {
    this.setAllMessageRead();
    this.getMessages();
    this.$store.dispatch("getMessagesList");
  },
  watch: {
    page() {
      this.getMessages();
    },
  },
  methods: {
    //消息列表
    async getMessages() {
      const lists = await getMessages({
        //status: this.status,
        page: this.page,
      });

      lists.list.forEach((item) => {
        item["flag"] = false as any;
      });

      this.pager = lists;
      this.messageList = lists.list;
      console.log("22", lists);
    },

    //消息全部已读
    async setAllMessageRead() {
      try {
        const res = await setAllMessageRead();

        localStorage.setItem("messNum", 0 as any);
      } catch (e) {}
    },

    caozuo(index) {
      //this.messageList[this.currentIndex].status = false;

      this.messageList[index].flag = !this.messageList[index].flag;

      //this.$refs.idd.style.display = "block";
    },
  },
});
