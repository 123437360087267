
import { Reason } from "@/api/zmkm";
import {
  ApiResponseError,
  getOrderDetail,
  getReasons,
  refundOrder,
  ROrderDetail,
  uploadFile,
} from "@/api/zmkm-api";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RefundApplyView",
  components: { Uploader },
  data() {
    return {
      order: undefined as ROrderDetail | undefined,
      reasons: [] as Reason[],
      type: 0,
      reasonId: undefined as number | undefined,
      price: 0.00 as any,
      desc: "",
      status: 0,
      certificateFiles: [] as UploaderFile[],
      certificateUrl: "",
      allSelected: false,
      finish: false
    };
  },
  async mounted() {
    this.order = await getOrderDetail(
      parseInt(this.$route.params.orderId as string)
    );

    this.order.descs.forEach((item) => {
      item["selected"] = false as boolean;
    });

    this.reasons = await getReasons();
    this.price = (this.order.priceFinal * 1).toFixed(2) as any;
  },
  methods: {
    orderDetail() {
      this.$router.push({
        name: "orderdetail",
        params: { orderId: this.order?.id },
      });
    },
    selectAll(selected) {
      this.allSelected = selected;
      this.order?.descs.forEach((item) => {
        item["selected"] = selected;
      });
    },

    selectedItem(desc) {
      desc.selected = !desc.selected;
      this.allSelected = !!this.order?.descs.every((item) => item["selected"]);
    },

    changePrice() {
      let priceFinal = this.order?.priceFinal as number;
      if (this.price > priceFinal) {
        this.price = priceFinal;
      }
    },

    async onSubmit() {
      const selected =
        this.order?.descs.filter((desc) => !!desc["selected"]) || [];
      if (!selected.length) {
        this.$message.show({ message: "请选择要退款的商品" });
        return;
      }

      if (this.status === 1) {
        if (!this.reasonId) {
          this.$message.show({ message: "请选择退款原因" });
          return;
        }
      }

      if (this.certificateFiles.length) {
        this.certificateUrl = await uploadFile(this.certificateFiles[0].file);
      }

      try {
        this.finish = true
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.$message.show({ message: e.response.message! });
        }
      }
    },

    async onCommentFinish(type: "close" | "on") {
      this.finish = false;
      if (type === "on") {
        const selected =
          this.order?.descs.filter((desc) => !!desc["selected"]) || [];
        await refundOrder(this.order!.id, {
          ordersDescs: selected.map((desc) => {
            return {
              id: desc.id,
              num: desc.num,
            };
          }),
          type: this.type,
          price: this.price,
          desc: this.desc,
          certificate: this.certificateUrl,
          reasonId: this.reasonId,
        });
        this.$router.back();
      }
    }
  },
});
