
import { MemberAccount } from "@/api/zmkm";
import {
  fixUrl,
  RMemberInfo,
  updateMemberInfo,
  uploadFile,
  getMemberInfo,
  updateUserLevel,
} from "@/api/zmkm-api";
import { assignWithoutUndefined } from "@/utils/object.utils";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "PersonalView",
  data() {
    return {
      nickname: "",
      gender: 1,
      birthday: "",
      avatarFile: undefined as File | undefined,
      avatarUrl: "",
      yearsModel: null as any,
      years: [] as any,
      monthsModel: null as any,
      months: [] as any,
      daysModel: null as any,
      days: [] as any,
    };
  },
  async mounted() {
    //console.log(this.memberInfo.memberAccount.id)
    //更新个人等级
    let ty = await updateUserLevel(this.memberInfo.memberAccount.id);
    console.log("===ty===")
    console.log(ty)

    if (this.memberInfo) {
      this.nickname = this.memberInfo.memberAccount.nickname;
      this.gender = this.memberInfo.memberAccount.gender;
      this.birthday = this.memberInfo.memberAccount.birthday;
      // console.log(this.memberInfo);
      this.init();
    }
  },
  computed: {
    ...mapState(["memberInfo"]),
  },
  methods: {
    init() {
      let year, month, day;
      if (!this.birthday) {
        var myDate = new Date();
        year = myDate.getFullYear(); //获取当前年~
        month = myDate.getMonth() + 1; //获取当前月
        day = myDate.getDate(); //获取当前日
      } else {
        let birthday = this.birthday.split("-");
        year = parseInt(birthday[0]);
        month = parseInt(birthday[1]);
        day = parseInt(birthday[2]);
      }

      this.initSelectYear(year);
      this.initSelectMonth();
      this.initSelectDay(year, month);

      this.yearsModel = year;
      this.monthsModel = month < 10 ? "0" + month : "" + month;
      this.daysModel = day < 10 ? "0" + day : "" + day;
    },

    initSelectYear(year) {
      year = 2022 as any;
      this.years = [];
      for (let i = 0; i < 100; i++) {
        this.years.push({ value: year - i, label: year - i + "年" });
      }
    },

    initSelectMonth() {
      this.months = [];
      // this.months.push({ value: 0, label: "请选择月" });
      for (let i = 1; i <= 12; i++) {
        this.months.push({
          value: i < 10 ? "0" + i : "" + i,
          label: (i < 10 ? "0" + i : "" + i) + "月",
        });
      }
    },
    initSelectDay(year, month) {
      var maxDay = this.getMaxDay(year, month);
      this.days = [];
      // this.days.push({ value: 0, label: "请选择日" });
      for (var i = 1; i <= maxDay; i++) {
        this.days.push({
          value: i < 10 ? "0" + i : "" + i,
          label: (i < 10 ? "0" + i : "" + i) + "日",
        });
      }
    },
    dateChange(type) {
      //1年 2月 3日 4 左 5右
      if (type == 1 || type == 2) {
        if (this.monthsModel == 0) {
          this.daysModel = 0;
          this.initSelectDay(this.yearsModel, 1);
        } else {
          this.initSelectDay(this.yearsModel, this.monthsModel);
        }
      }
    },

    getMaxDay(year, month) {
      var new_year = year; //取当前的年份
      var new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        //如果当前大于12月，则年份转到下一年
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate(); //获取当月最后一天日期
    },

    onSelectAvatarFile(file?: File) {
      //1111
      this.avatarFile = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          this.avatarUrl = ev.target?.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        this.avatarUrl = "";
      }
    },
    async onSave() {
      if (this.nickname == "") {
        this.$message.show({ message: "请填写名字" });
        return;
      }

      if (this.monthsModel == 0 || this.daysModel == 0) {
        this.$message.show({ message: "请选择生日" });
        return;
      }

      this.birthday =
        this.yearsModel + "-" + this.monthsModel + "-" + this.daysModel;

      const updated: Partial<MemberAccount> = {
        nickname: this.nickname ? this.nickname.trim() : undefined,
        gender: this.gender >= 0 ? this.gender : undefined,
        birthday: this.birthday ? this.birthday.trim() : undefined,
      };
      if (this.avatarFile) {
        updated.avatar = await uploadFile(this.avatarFile);
        updated.avatar = fixUrl(updated.avatar);
      }

      await updateMemberInfo(updated);

      this.$store.commit("setMemberInfo", {
        memberAccount: assignWithoutUndefined(
          this.$store.state.memberInfo?.memberAccount,
          updated
        ),
      } as RMemberInfo);
      this.avatarFile = undefined;
      this.avatarUrl = "";

      this.$message.show({ message: "保存成功" });
    },
  },
});
