<template>
  <div class="over-time-bottom">
    <div class="text-center">
      <span>{{ h0 }}</span>
    </div>
    <div class="text-center">
      <span>{{ h1 }}</span>
    </div>
    <div class="text-center">
      <span>{{ h2 }}</span>
    </div>
    <div class="text-center colon white">
      <span>:</span>
    </div>
    <div class="text-center">
      <span>{{ m1 }}</span>
    </div>
    <div class="text-center">
      <span>{{ m2 }}</span>
    </div>
    <div class="text-center colon white">
      <span>:</span>
    </div>
    <div class="text-center">
      <span>{{ s1 }}</span>
    </div>
    <div class="text-center">
      <span>{{ s2 }}</span>
    </div>
  </div>
</template>

<script setup>

import {
  getActiveTime
} from "@/api/zmkm-api";


import { ref, reactive, onMounted } from "vue";

const secTime = 1000;
const minTime = 60 * secTime;
const hourTime = 60 * minTime;
const dayTime = 24 * hourTime;

const d = ref(0); // 天
const h = ref(0); // 时
const m = ref(0); // 分
const s = ref(0); // 秒

const d1 = ref(0);
const d2 = ref(0);
const h0 = ref(0);
const h1 = ref(0);
const h2 = ref(0);
const m1 = ref(0);
const m2 = ref(0);
const s1 = ref(0);
const s2 = ref(0);

let timeStr = '';

function byoYomi() {
  const date = new Date();
  const now = date.getTime();
  const endData = new Date(timeStr);
  const end = endData.getTime();
  const leftTime = end - now;
  if (leftTime > 0) {
    d.value = Math.floor(leftTime / dayTime);
    h.value = Math.floor((leftTime - d.value * dayTime) / hourTime);
    m.value = Math.floor(
      (leftTime - d.value * dayTime - h.value * hourTime) / minTime
    );
    s.value = Math.floor(
      (leftTime - d.value * dayTime - h.value * hourTime - m.value * minTime) /
        secTime
    );
    h.value = d.value * 24 + h.value;
  }
  if (s.value > 0 || m.value > 0 || m.value > 0) {
    if (d.value < 10) {
      d1.value = 0;
      d2.value = d.value;
    } else {
      d1.value = d.value.toString().substring(0, 1);
      d2.value = d.value.toString().substring(1, 2);
    }
    if (h.value > 99) {
      h0.value = h.value.toString().substring(0, 1);
      h1.value = h.value.toString().substring(1, 2);
      h2.value = h.value.toString().substring(2, 3);
    } else if (h.value < 10) {
      h0.value = 0;
      h1.value = 0;
      h2.value = h.value;
    } else {
      h0.value = 0;
      h1.value = h.value.toString().substring(0, 1);
      h2.value = h.value.toString().substring(1, 2);
    }
    if (m.value < 10) {
      m1.value = 0;
      m2.value = m.value;
    } else {
      m1.value = m.value.toString().substring(0, 1);
      m2.value = m.value.toString().substring(1, 2);
    }
    if (s.value < 10) {
      s1.value = 0;
      s2.value = s.value;
    } else {
      s1.value = s.value.toString().substring(0, 1);
      s2.value = s.value.toString().substring(1, 2);
    }
    setTimeout(byoYomi, 1000);
  }
}

async function ActiveTime(){
  const list = await getActiveTime();
  timeStr = list[0].effectiveTimeEnd;
}

onMounted(() => {
  ActiveTime().then(()=>{
    byoYomi();
  });
});
</script>
