
import { getCategories } from "@/api/zmkm-api";
import { groupBy } from "@/utils/array.utils";
import { Categories } from "@/api/zmkm";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "Nav",
  components: {},
  props: {},
  data: () => {
    return {
      categories: {} as Record<number, Categories[]>,
      isSubNavShow: false,
      spell: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ] as any,
    };
  },
  mounted() {
    // this.spell.map(item=>{
    // })

    let body = document.querySelector("body") as any;
    body.addEventListener(
      "click",
      () => {
        this.isSubNavShow = false;
      },
      false
    );

    this.getCategories();
  },
  methods: {
    navCategory(type, id) {
      this.isSubNavShow = false;
      this.$router.push({
        name: "goodscategory",
        params: { name: type, value: id },
      });
      console.log("123")
    },

    async getCategories() {
      this.categories = groupBy(await getCategories(), (item) => item.type);
    },

    toBjd() {
      if (!this.memberInfo) {
        this.$message.show({ message: "请先登录" });
        return;
      }

      this.$router.push("/pricesheet");
    },
  },

  computed: {
    ...mapState(["cfg", "memberInfo"]),
  },
});
