import { SelectedGoods } from "@/api/zmkm-api";
import router from "@/router";
import store from "@/store";

export function goToSettlement(selectGoods?: SelectedGoods[]) {
  // console.log("-888-")
  // console.log(selectGoods);
  store.commit("setSelectedGoods", selectGoods);
  router.push("/settlement");
}
