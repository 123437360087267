
import { getOrders, ROrder } from "@/api/zmkm-api";
import {
  addOrderComment,
  getOrderDetail,
  ROrderDesc,
  ROrderDetail,
} from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import Uploader, { UploaderFile } from "@/components/Uploader.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommentList",
  components: { Pager , Uploader},
  props: {
    status: {
      type: Number,
    },
  },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      orders: [] as ROrder[],
      ordercomment: 0,
      commentVisible: false,
      commentFinishVisible: false,
      commentOrderDesc: undefined as ROrderDesc | undefined,
      commentLevel: 5,
      commentDesc: "",
      commentDescImageFiles: [] as UploaderFile[],
      commentDescImage: "",
    };
  },
  async mounted() {
    this.getOrders();
  },
  watch: {
    page() {
      this.getOrders();
    },
  },
  methods: {
    async getOrders() {
      const comments = await getOrders({
        isInCommented: this.status === 1,
        isInNeedComment: this.status !== 1,
      });
      this.pager = comments;
      this.orders = comments.list;
    },
    async onComment(id) {
      this.commentDescImage =
        // @ts-ignore
        await this.$refs.commentDescImageFilesUploader.doUpload();
        await addOrderComment(id, {
        ordersDescId: this.commentOrderDesc!.id,
        descText: this.commentDesc,
        descImage: this.commentDescImage,
        lever: this.commentLevel,
      });
      this.commentVisible = false;
      this.commentFinishVisible = true;
      
    },
    onCommentFinish(type: "close" | "home" | "view") {
      this.commentFinishVisible = false;
      if (type === "home") {
        this.$router.replace("/");
      } else if (type === "view") {
        location.reload();
        this.$router.replace("/personal/comment");
      }
    }
  }
});
