
import { Reason } from "@/api/zmkm";
import {
  addCartGoods,
  cancelOrder,
  getOrders,
  getReasons,
  ROrder,
  confirmOrder,
  getOrderDetail,
  ROrderDetail,
} from "@/api/zmkm-api";
import CountDown from "@/components/CountDown.vue";
import Pager from "@/components/Pager.vue";
import { toStatement } from "@babel/types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderList",
  components: { CountDown, Pager },
  props: {
    statusLabel: {
      type: String,
    },
    status: {
      type: Number,
    },
    reload: {
      type: Object,
    },
  },
  data() {
    return {
      statusArr: {
        "0": "待付款",
        "1": "审核中",
        "2": "待发货",
        "3": "待收货",
        "4": "已完成",
        "5": "已评价",
        "6": "已关闭",
        "7": "支付超时",
        "8": "售后中",
        "9": "已售后",
        "10": "取消售后",
      },

      confirmVisible: false,

      pager: undefined as any,
      page: 1,
      orders: [] as ROrder[],
      reasons: [] as Reason[],
      reasonId: 0,
      currentOrderId: -1,
      cancelVisible: false,
      cancelFinishVisible: false,
      orderCurrentId: 0,
      orderd: undefined as ROrderDetail | undefined,
    };
  },
  async mounted() {
    this.getOrders();
    this.reasons = await getReasons();
    this.reasonId = this.reasons[0]?.id || 0;
  },
  watch: {
    reload() {
      this.getOrders();
    },
    page() {
      this.getOrders();
    },
  },
  methods: {
    async getOrders() {
      const orders = await getOrders({
        memberAccountId: this.$store.state.memberInfo?.memberAccount.id || 0,
        status: this.status,
        page: this.page,
      });
      this.pager = orders;
      this.orders = orders.list;
    },
    onDetail(order: ROrder) {
      this.$router.push({ name: "orderdetail", params: { orderId: order.id } });
    },

    onExpress(order: ROrder) {
      this.$router.push({
        name: "express",
        params: { orderId: order?.id },
      });
    },
    onInvoice(order: ROrder) {
      this.$router.push({
        name: "invoiceapply",
        params: { orderId: order?.id },
      });
    },
    //订单取消确认框
    onSureCancel(order: ROrder) {
      this.currentOrderId = order.id;
      this.cancelVisible = true;
    },

    async onCancel() {
      await cancelOrder(this.currentOrderId, {
        reasonId: this.reasonId,
      });

      this.cancelVisible = false;
      this.cancelFinishVisible = true;
      await this.getOrders();
    },

    onCancelFinish(type: "close" | "home") {
      this.cancelFinishVisible = false;
      if (type === "home") {
        this.$router.replace("/");
      }
    },

    async onConfirm() {
      await confirmOrder(this.orderCurrentId);
      await this.getOrders();
      //await this.getOrderDetail();
      this.confirmVisible = false;
      
    },

    onRefund(order: ROrder) {
      this.$router.push({
        name: "refundapply",
        params: { orderId: order.id },
      });
    },
    onPay(id: any) {
      this.$router.push({
        name: "cashier",
        params: { orderId: id },
      });
    },
    //重新购买
    async againBuy(goods_id: any,goods_specs_mix_desc_id:any,num:any){
      // console.log(goods_id);
      // console.log(goods_specs_mix_desc_id);
      // console.log(num);
      await addCartGoods([
        {
          goodsId: goods_id,
          goodsSpecsMixDescId: goods_specs_mix_desc_id,
          num: num,
          isYouhui:'',
          commonId:''
        },
      ]);
      this.$message.show({ message: "购物车添加成功" });
      this.$store.dispatch("getCartNum");
    },
  },
});
