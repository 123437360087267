<template>
  <div class="body">
    <div class="form">
      <div class="title clear">
        <div class="name left">
          <span class="size16 font-weight">首营认证</span>
        </div>
        <div class="btn right pointer" @click="$router.push('/')">
          <span class="coffee">跳过</span>
        </div>
        <div class="btn right pointer" @click="warnVisible = true">
          <span class="coffee">联系客服认证</span>
        </div>
      </div>
      <div class="layout">
        <div class="lay-tab">
          <div><span class="size16 font-weight">Step 01 基础资料</span></div>
        </div>
        <div class="lay-content">
          <div class="form-item clear">
            <div class="name left"><span>企业名称：</span></div>
            <div class="input right">
              <input
                type="text"
                name="name"
                v-model="editAddress.firmName"
                autocomplete="off"
                placeholder="请输入企业名称"
              />
            </div>
          </div>
          <div class="form-item clear">
            <div class="name left"><span>企业性质：</span></div>
            <div class="input right">
              <select
                name="company"
                class="select"
                v-model="editAddress.firmType"
              >
                <!-- <option value="">请选择企业性质</option>
                <option value="1">营利性医疗机构</option>
                <option value="2">非营利性医疗机构</option>
                <option value="3">药品零售企业</option>
                <option value="4">药品批发企业</option> -->
                <option value="">请选择企业性质</option>
                <option value="1">营利性医疗机构</option>
                <option value="2">营利性医疗机构（免税）</option>
                <option value="3">非营利性医疗机构（公立）</option>
                <option value="4">非营利性医疗机构（私立）</option>
                
                <option value="5">零售企业</option>
                <option value="6">批发企业</option>
                <option value="7">生产企业</option>

              </select>
            </div>
          </div>
          <div class="form-item clear">
            <div class="name left"><span>所在区域：</span></div>
            <div class="input sub-input right">
              <select class="select" name="company" v-model="area_index">
                <option :value="-1">请选择区域</option>
                <template v-if="province_index > -1 && city_index > -1">
                  <option
                    v-for="(item, index) in areaData[province_index].children[
                      city_index
                    ].children"
                    :key="index"
                    :value="index"
                  >
                    {{ item.name }}
                  </option>
                </template>
              </select>
            </div>
            <div class="input sub-input right">
              <select class="select" name="company" v-model="city_index">
                <option :value="-1">请选择市</option>
                <template v-if="province_index > -1">
                  <option
                    v-for="(item, index) in areaData[province_index].children"
                    :key="index"
                    :value="index"
                  >
                    {{ item.name }}
                  </option>
                </template>
              </select>
            </div>
            <div class="input sub-input right">
              <select class="select" name="company" v-model="province_index">
                <option :value="-1">请选择省</option>
                <option
                  v-for="(item, index) in areaData"
                  :key="index"
                  :value="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-item clear">
            <div class="name left"><span>详细地址：</span></div>
            <div class="textarea right">
              <textarea
                v-model="editAddress.address"
                cols="30"
                rows="4"
                placeholder="请填写企业详细地址"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="layout">
        <div class="lay-tab">
          <div><span class="size16 font-weight">更多信息</span></div>
        </div>
        <div class="lay-content">
          <div class="form-item clear">
            <div class="name left"><span>纳税人识别号：</span></div>
            <div class="input right">
              <input
                type="text"
                v-model="editAddress.businessLicenseNo"
                placeholder="请填写您的纳税人识别号"
              />
            </div>
          </div>

          <div class="form-item clear">
            <div class="name left"><span>开户行：</span></div>
            <div class="input right">
              <input
                type="text"
                v-model="editAddress.bankName"
                placeholder="请选择您的开户行"
              />
            </div>
          </div>

          <div class="form-item clear">
            <div class="name left"><span>开户账号：</span></div>
            <div class="input right">
              <input
                type="text"
                v-model="editAddress.bankAccount"
                placeholder="请选择您的银行账号"
              />
            </div>
          </div>
          <div class="form-item clear">
            <div class="name left"><span>联系人：</span></div>
            <div class="input right">
              <input
                type="text"
                v-model="editAddress.contacts"
                placeholder="请填写您的称呼"
              />
            </div>
          </div>
          <div class="form-item clear">
            <div class="name left"><span>电话：</span></div>
            <div class="input right">
              <input
                type="text"
                v-model="editAddress.tel"
                placeholder="请填写您的联系电话"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="prove-btn white bg-coffee" @click="nextProve">
        <span>下一步，上传资质图片</span>
      </div>
    </div>

    <!-- 取消订单成功弹出框 -->
    <div v-if="warnVisible" class="dialog dialog-cancel-success">
      <div class="nav-content">
        <div class="success-info">
          <img
            @click="warnVisible = false"
            class="dialog-close right pointer"
            src="image/dialog-close.png"
          />
          <img class="success-icon" src="image/call-tel-icon.png" />
          <div>
            <span class="size16 font-weight" style="display:block;margin-top:10px">联系客服</span>
            <span class="gray">0571-88888888</span>
          </div>
          <!-- <div class="warn">
            <span class="gray">0571-88888888</span>
          </div> -->
        </div>
        <div class="dialog-bottom">
          <div
            @click="warnVisible = false"
            class="dialog-normal-btn bg-coffee white pointer"
          >
            <span>确认</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="warnVisible" class="dialog-mask"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { areas } from "@/assets/areas.ts";

let origin_province_list = Object.keys(areas.province_list).map((item) => {
  return {
    key: item,
    name: areas.province_list[item],
  };
});
let origin_city_list = Object.keys(areas.city_list).map((item) => {
  return {
    key: item,
    name: areas.city_list[item],
  };
});
let origin_county_list = Object.keys(areas.county_list).map((item) => {
  return {
    key: item,
    name: areas.county_list[item],
  };
});
let areaData = origin_province_list.map((province) => {
  province.children = dealcity(province).map((city) => {
    city.children = dealcounty(city);
    return city;
  });
  return province;
});

function dealcity(province) {
  return origin_city_list.filter((item) => {
    return item.key.startsWith(province.key.slice(0, 2));
  });
}

function dealcounty(city) {
  return origin_county_list.filter((item) => {
    return item.key.startsWith(city.key.slice(0, 4));
  });
}

export default defineComponent({
  name: "ProveView",
  components: {},
  data() {
    return {
      editAddress: {
        firmName: "", //企业名称
        firmType: "", //企业类型
        province: "",
        city: "",
        district: "",
        address: "", //详细地址,
        businessLicenseNo: "", //纳税人识别号
        bankName: "", //银行名称
        bankAccount: "", //银行账号
        contacts: "", //联系人
        tel: "", //电话
      },
      province_index: -1,
      city_index: -1,
      area_index: -1,
      areaData,
      warnVisible: false,
    };
  },
  watch: {
    province_index(val) {
      this.city_index = -1;
      this.area_index = -1;
      this.editAddress.province = this.areaData[val].key;
      this.editAddress.city = "";
      this.editAddress.district = "";
    },
    city_index(val) {
      this.area_index = -1;
      val > -1 &&
        (this.editAddress.city =
          this.areaData[this.province_index].children[val].key);
      this.editAddress.district = "";
    },
    area_index(val) {
      if (val > -1) {
        this.editAddress.district =
          this.areaData[this.province_index].children[this.city_index].children[
            val
          ].key;
      }
    },
  },
  methods: {
    nextProve() {
      if (this.editAddress.firmName === "") {
        this.$message.show({ message: "请填写企业名称" });
        return;
      }

      if (this.editAddress.firmType === "") {
        this.$message.show({ message: "请选择企业性质" });
        return;
      }

      if (this.editAddress.district == "") {
        this.$message.show({ message: "请选择所在区域" });
        return;
      }

      if (this.editAddress.address === "") {
        this.$message.show({ message: "请填写详细地址" });
        return;
      }

      if (this.editAddress.businessLicenseNo === "") {
        this.$message.show({ message: "请填写纳税人识别号" });
        return;
      }
      if (this.editAddress.bankName === "") {
        this.$message.show({ message: "请填写开户行" });
        return;
      }

      if (this.editAddress.bankAccount === "") {
        this.$message.show({ message: "请填写开户账号" });
        return;
      }

      if (this.editAddress.contacts === "") {
        this.$message.show({ message: "请填写联系人" });
        return;
      }

      if (this.editAddress.tel === "") {
        this.$message.show({ message: "请填写电话" });
        return;
      }

      if (!/^1\d{10}$/.test(this.editAddress.tel)) {
        this.$message.show({ message: "号码格式有误" });
        return false;
      }

      this.editAddress.province = this.areaData[this.province_index].key;
      this.editAddress.city =
        this.areaData[this.province_index].children[this.city_index].key;
      this.editAddress.district =
        this.areaData[this.province_index].children[this.city_index].children[
          this.area_index
        ].key;

      localStorage.setItem("preveinfo", JSON.stringify(this.editAddress));

      this.$router.push("./provenext");
    },

    //打开客服
    toKefu() {
      var kfUrl = localStorage.getItem("kfUrl");
      window.open(kfUrl, "_blank"); // 新窗口打开外链接
    },
  },
});
</script>

<style scoped lang="scss">
.body {
  padding: 20px 0;
  background-color: #efefef;
}

.body .form {
  width: 960px;
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
  padding-top: 10px;
  box-sizing: border-box;
}
.body .layout {
  width: auto;
}
.form .title {
  height: 60px;
  border-bottom: 1px solid #efefef;
}

.form .title .name {
  line-height: 60px;
}

.form .title .btn {
  margin-top: 12px;
  margin-left: 10px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  border-radius: 18px;
  border: 1px solid #bfaa75;
  box-sizing: border-box;
}

.form .layout {
  margin-top: 20px;
  border: 1px solid #efefef;
}

.form .lay-tab {
  line-height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #efefef;
  background-color: #fafafa;
}
.form .lay-content {
  padding: 20px 40px;
}

.form .lay-content .form-item {
  margin-bottom: 16px;
}

.form .lay-content .form-item .name {
  line-height: 40px;
  width: 12%;
  text-align: right;
}
.form .lay-content .form-item .input {
  width: 86%;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}
.form .lay-content .form-item .input input {
  line-height: 40px;
  width: 100%;
}

.form .lay-content .form-item .input select {
  line-height: 40px;
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #757575;
}

.form .lay-content .form-item .sub-input {
  width: 28%;
  margin-left: 8px;
}

.form .lay-content .form-item .textarea {
  width: 86%;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}
.form .lay-content .form-item .textarea textarea {
  line-height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

.form .lay-content .form-item .radio {
  line-height: 40px;
}
.form .lay-content .form-item .radio input {
  margin-top: -2px;
  margin-left: 20px;
}

.form .lay-content .form-item .btn {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 18px;
  border: 1px solid #bfaa75;
  color: #bfaa75;
  margin-top: 2px;
}

.form .lay-content .form-photo {
  display: block;
  border-top: 1px solid #efefef;

  padding: 20px 0;
}
.form .lay-content .photo-item {
  width: 160px;
  height: 100px;
  margin-right: 16px;
  position: relative;
}
.form .lay-content .photo-item .photo {
  width: 100%;
  height: 100%;
}
.form .lay-content .photo-item .photo-close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
}

.prove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 30px;
  width: 160px;
  margin: auto;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
}

.dialog {
  .dialog-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    .dialog-normal-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 4px;
      height: 36px;
      padding: 0 46px;
      margin: 0 6px;
    }
  }
}

.dialog-cancel-success {
  width: 320px;
  height: 180px;

  .success-info {
    position: relative;
    text-align: center;

    .dialog-close {
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
    }

    .success-icon {
      width: 40px;
      height: 40px;
      margin: 6px 0px;
    }

    .warn {
      line-height: 40px;
    }
  }
}
</style>
