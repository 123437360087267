
import { getOrderDetail, ROrderDetail } from "@/api/zmkm-api";
import { defineComponent } from "vue";
import { Goods } from "@/api/zmkm";
import { searchGoods } from "@/api/zmkm-api";

export default defineComponent({
  name: "PaySuccessView",
  components: {},
  data: () => {
    return {
      order: undefined as ROrderDetail | undefined,
      goodsList: [] as any,
    };
  },
  async mounted() {
    this.order = await getOrderDetail(
      parseInt(this.$route.params.orderId as string)
    );

    await this.getGoodsList();
  },
  methods: {
    async getGoodsList() {
      let result = await searchGoods({
        inHotSale: true,
        size: 8,
      });

      console.log(this.goodsList, "--------------------");

      this.goodsList = result.list;
    },

    onDetail(goods: Goods) {
      this.$router.push({ name: "goodsdetail", params: { id: goods.id } });
    },

    onGoaway() {
      this.$router.replace("/");
    },
    onViewDetail() {
      this.$router.replace({
        name: "orderdetail",
        params: {
          orderId: this.order!.id,
        },
      });
    },
  },
});
