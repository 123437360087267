
import { uploadFile } from "@/api/zmkm-api";
import { defineComponent, PropType } from "vue";

export interface UploaderFile {
  file: File;
  url?: string;
}

export default defineComponent({
  name: "Uploader",
  props: {
    modelValue: {
      type: Array as PropType<UploaderFile[]>,
      default: () => [],
    },
    maxCount: {
      type: Number,
      default: () => 1,
    },
    size: {
      type: String as PropType<"normal" | "middle" | "mini" | "rectangle">,
      default: () => "normal",
    },
    type: {
      type: String as PropType<"image">,
      default: () => "image",
    },
    autoUpload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      timer: 0,
      seconds: 0,
    };
  },
  computed: {
    accept() {
      if (this.type === "image") {
        return "image/png,image/jpeg";
      }
      return "";
    },
  },
  methods: {
    onAdd() {
      (this.$refs.input as HTMLInputElement).click();
    },
    onSelectFile(index: number, file: File) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          let newModelValue: UploaderFile[];
          if (index === -1) {
            newModelValue = [
              ...this.modelValue,
              {
                file: file,
                url: ev.target?.result as string,
              },
            ];
          } else {
            newModelValue = this.modelValue;
            newModelValue[index] = {
              file: file,
              url: ev.target?.result as string,
            };
          }
          this.$emit("update:modelValue", newModelValue);
        };
        reader.readAsDataURL(file);
      }
    },
    onClick(index: number) {
      (this.$refs[`input${index}`] as HTMLInputElement[])[0].click();
    },
    onClose(index: number) {
      this.$emit(
        "update:modelValue",
        this.modelValue!.filter((_, i) => i !== index)
      );
    },
    async doUpload() {
      if (!this.modelValue?.length) {
        return "";
      }
      const files = await Promise.all(
        this.modelValue!.map((file) => {
          return uploadFile(file.file);
        })
      );
      return files.join(",");
    },
  },
});
