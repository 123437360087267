
import { getMessages, RMemberInfo,getMemberInfo } from "@/api/zmkm-api";import router from "@/router";

import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "PersonalView",
  data() {
    return {
      orderNavArr: {
        "/personal/order": true,
        "/personal/orderdetail": true,
        "/personal/refundapply": true,
        "/personal/invoiceapply": true,
        "/personal/express": true,
      },
      messNum: 0,
      // memberInfo: {
      //   avatar: "",
      //   nickname: "",
      //   gender: 0,
      //   level: "",
      // },
      memberInfo: {},
      info: {},
    };
  },
  created() {
    //this.getMessages();

    this.messNum = localStorage.getItem("messNum") as any;
    this.getInfo();
    if (!this.memberInfo) {
      this.$router.push("/");
    }

    // this.memberInfo = await getMemberInfo();
    // console.log("==tytyty==")
    // console.log(this.memberInfo.memberAccount)
    //console.log(this.memberInfo)
  },
  computed: {
    ...mapState(["memberInfo"]),
  },
  // computed: {
  //   ...mapState(["memberInfo"]),
  // },
  methods: {
    //消息列表
    // async getMessages() {
    //   const lists = await getMessages({
    //       status: 0,
    //   });

    //    //this.messNum = lists.total;

    //   localStorage.setItem('messNum',lists.total as any);

    //   //this.$store.state.memberInfo?.messageNumber = lists.total as any;

    //   //console.log(lists);
    // },

    orderDetail() {
      this.$router.push("./orderdetail");
    },
    async getInfo() {
      // this.info = await getMemberInfo();
      this.memberInfo = await getMemberInfo();
      console.log(this.memberInfo);
      console.log(this.$store.state.memberInfo);
    },
    goProve() {
      console.log(this.memberInfo.memberAccount.authStatus)
      if (!this.memberInfo.memberAccount.authStatus) {
        this.$router.replace("/prove");
      } else if (this.memberInfo.memberAccount.authStatus == 1) {
        this.$message.show({ message: "你已认证" });
      } else {
        this.$message.show({ message: "首映认证审核中" });
      }
    },
  },
});
