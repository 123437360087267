
import { defineComponent } from "vue";

import MessageList from "./components/MessageList.vue";

export default defineComponent({
  name: "messageview",
  components: {
    MessageList,
  },
  data() {
    return {};
  },
  methods: {},
});
