
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "Footer",
  props: {},
  data() {
    return {
      gotop: false,
      warnVisible: false,
    };
  },
  mounted() {
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);

    //美洽客服链接
    //localStorage.setItem('kfUrl',"https://f346b389dbf21.chatnow.mstatik.com/widget/standalone.html?eid=a5fe44a2c8a019a0937f2f852f04904c");
    localStorage.setItem(
      "kfUrl",
      "http://m.longlaimall.com/chatlink.html"
    );

    //接口域名
    localStorage.setItem(
      "apiUrl",
      "https://zhimakaimenminiappapi.longlaimall.com/"
    );
  },

  methods: {
    goProve() {
      if (!this.memberInfo) {
        this.$message.show({ message: "请先登录" });
        return;
      }

      if (this.memberInfo.memberAccount.authStatus == 1) {
        this.$message.show({ message: "首营已认证" });
        return;
      } else if (this.memberInfo.memberAccount.authStatus == -1) {
        this.$message.show({ message: "首营认证审核中" });
        return;
      }

      this.$router.push("/prove");
    },

    goPricesheet() {
      if (!this.memberInfo) {
        this.$message.show({ message: "请先登录" });
        return;
      }

      this.$router.push("/pricesheet");
    },

    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    //打开客服
    toKefu() {
      window.open(localStorage.getItem("kfUrl") as string, "_blank"); // 新窗口打开外链接
    },

    goToGongan(gongan){
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="+gongan)
    }
  },

  computed: {
    ...mapState(["cfg", "memberInfo"]),
  },
});
