
import { ExpressAddress } from "@/api/zmkm";
import { provinces } from "@/utils/area.utils";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "editaddress",
  props: {
    isAdd: {
      type: Boolean,
      default: () => false,
    },
    initAddress: {
      type: Object as PropType<ExpressAddress>,
    },
  },
  data() {
    return {
      address: {} as ExpressAddress,
      provinces: provinces,
      areas: [0, 0, 0],
      select2: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.address = this.initAddress || ({} as any);
      let province = provinces.findIndex(
        (province) => province.code === `${this.address.province}`
      );
      if (province === -1) {
        province = 0;
      }
      let city =
        province === -1
          ? 0
          : provinces[province].children.findIndex(
              (city) => city.code === `${this.address.city}`
            );
      if (city === -1) {
        city = 0;
      }
      let country =
        city === -1
          ? 0
          : provinces[province].children[city].children.findIndex(
              (country) => country.code === `${this.address.district}`
            );
      if (country === -1) {
        country = 0;
      }
      this.areas = [province, city, country];
      if (!this.initAddress) {
        this.address.province = parseInt(provinces[province].code);
        this.address.city = parseInt(provinces[province].children[city].code);
        this.address.district = parseInt(
          provinces[province].children[city].children[country].code
        );
        this.address.statusDefault = 1;
      }
    })
  },
  methods: {
    onAreaSelect(code: string, level: 1 | 2 | 3) {
      if (level === 1) {
        this.areas[0] = provinces.findIndex(
          (province) => province.code === code
        );
        this.areas[1] = 0;
        this.address.city = parseInt(this.provinces[this.areas[0]].children[0].code);
        this.address.district = parseInt(this.provinces[this.areas[0]].children[this.areas[1]].children[0].code);

      } else if (level === 2) {
        this.areas[1] = provinces[this.areas[0]].children.findIndex(
          (city) => city.code === code,
          console.log(code)
        );
        this.areas[2] = 0;
        this.address.district = parseInt(this.provinces[this.areas[0]].children[this.areas[1]].children[0].code);
      };
      
    },
    onSave() {
      if (!this.address.name) {
        this.$message.show({ message: "请填写收货人姓名" });
        return;
      }

      if (!this.address.tel) {
        this.$message.show({ message: "请输入手机号码" });
        return;
      }

      if (!/^1\d{10}$/.test(this.address.tel)) {
        this.$message.show({ message: "请输入正确的手机号" });
        return;
      }

      if (!this.address.address) {
        this.$message.show({ message: "请输入详细地址" });
        return;
      }

      //console.log(this.address);
      this.$emit("save", this.address);

      this.$message.show({ message: "添加成功" });
    },
    // 返回
    onBack() {
      this.$emit("close");
    },
  },
});
