
import { defineComponent } from "vue";
import { mpGenQRCode,getMemberInfo } from "@/api/zmkm-api";
import { mapState } from "vuex";
export default defineComponent({
  name: "inviteview",
  data() {
    return {
      copyData: "",
      getValue: "",
      fxurl: "", //分享链接
      qrCodeImg: "",
      talkId: "1",
    };
  },

  async mounted() {
    let member = await getMemberInfo();
    await this.getQrCode();
    console.log(this.memberInfo, "----sss----s");
    this.fxurl =
      // "http://storehouse.longlaimall.com/web/index.html?talk=" +
      "http://index.doing365.com/index.html?talk=" +
      this.memberInfo.memberAccount.talkId +
      "#/pullnew";
  },

  computed: {
    ...mapState(["memberInfo"]),
  },

  methods: {
    //复制
    handleCopy() {
      const url = this.fxurl;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.show({ message: "复制成功" });
      oInput.remove();
    },

    async getQrCode() {
      let url = await mpGenQRCode({
        path:
          "/pages/mall/invite/invite?talk=" +
          this.memberInfo.memberAccount.talkId,
        envVersion: "trial",
      });

      this.qrCodeImg = url as string;

      console.log(url, "url-------");
    },
  },
});
