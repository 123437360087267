
import { GoodsSpecsMixDesc } from "@/api/zmkm";
import {
  addCartGoods,
  RGoods,
  searchGoods,
  searchGoodsTwo,
  SelectedGoods,
} from "@/api/zmkm-api";
import NumberInput from "@/components/NumberInput.vue";
import Selector from "@/components/Selector.vue";
import { goToSettlement } from "@/utils/settlement.utils";
import { defineComponent } from "vue";

interface GoodsExtend extends RGoods {
  num: number;
  specsMixDesc: GoodsSpecsMixDesc;
  specsMixDescId: number;
  selected: boolean;
}

export default defineComponent({
  name: "PriceSheetDetailView",
  components: { Selector, NumberInput },
  data: () => {
    return {
      goodsList: [] as GoodsExtend[],
      keywords: "",
      selectgood: [] as GoodsExtend[],
    };
  },

  async mounted() {
    this.getGoods();
  },
  computed: {
    allSelected: {
      get() {
        return this.goodsList.every((goods) => goods.selected);
      },
      set(selected: boolean) {
        this.selectgood.splice(0,this.selectgood.length)
        this.goodsList.forEach((goods) =>{
          goods.selected = selected;
          if(goods.selected){
            this.selectgood.push(goods)
          } else {
            this.selectgood.splice(0,this.selectgood.length)
          }
        });
        console.log(this.selectgood,"wjq is god")
      },
    },
  },
  methods: {
    select(goods){
      if(!goods.selected){
        this.selectgood.push(goods)
      } else {
        this.selectgood.forEach((item) => {
          var index = this.selectgood.indexOf(item);
          console.log(index,"下标")
          console.log(goods.id,"商品编号")
          console.log(item.id,"当前商品编号")
          if( goods.id === item.id) {
            this.selectgood.splice(index,1)
          }
        })
      }
      
      console.log(this.selectgood,"选中商品")
    },
    async getGoods() {

      this.goodsList = (
        await searchGoodsTwo({
          priceListId: parseInt(this.$route.params.id as string),
          getSpecsList: true,
          size: Number.MAX_SAFE_INTEGER,
          keywords: this.keywords,
        })
      ).list as GoodsExtend[];
      
      console.log("+++11",this.goodsList);

      this.goodsList.forEach((goods) => {
        goods.num = 1;
        goods.specsMixDesc = goods.specsList![0];
        goods.specsMixDescId = goods.specsMixDesc?.id;
        goods.selected = false;
      });

      // this.goodsList = (
      //   await searchGoods({
      //     priceListId: parseInt(this.$route.params.id as string),
      //     getSpecsList: true,
      //     size: Number.MAX_SAFE_INTEGER,
      //     keywords: this.keywords,
      //   })
      // ).list as GoodsExtend[];
      
      // console.log("+++",this.goodsList);

      // this.goodsList.forEach((goods) => {
      //   goods.num = 1;
      //   goods.specsMixDesc = goods.specsList![0];
      //   goods.specsMixDescId = goods.specsMixDesc?.id;
      //   goods.selected = false;
      // });
    },

    async onSettlement(isAddCart: boolean) {
      if (this.goodsList.every((goods) => !goods.selected)) {
        this.$message.show({ message: "请先选择商品" });
        return;
      }
      let data = this.goodsList.find(
        (item) => item.selected && item.specsMixDesc.num == 0
      );

      if (data) {
        this.$message.show({
          message: "库存不足",
        });
        return;
      }

      if (
        this.goodsList.some((item) => item.selected && String(item.num) === "")
      ) {
        this.$message.show({
          message: "请先选择数量",
        });
        return;
      }
      let commonId = this.$route.params.id as string;

      const selectGoods = this.goodsList
        .filter((goods) => goods.selected)
        .map((goods) => {
          return {
            goodsId: goods.id,
            goodsSpecsMixDescId: goods.specsMixDescId,
            num: goods.num,
            isYouhui:"baojia",
            commonId:commonId,
          } as SelectedGoods;
        });
      
      console.log(selectGoods);
        // return false;
      if (isAddCart) {
        await addCartGoods(selectGoods);
        this.$message.show({ message: "添加成功" });
      } else {
        goToSettlement(selectGoods);
      }
    },

    search() {
      this.getGoods();
    },

    onSub(item) {
      if (item.num > 1) {
        item.num = item.num - 1;
      }
    },

    onInput(item) {
      console.log(item);
      if (item.num != "" && item.num < 1) {
        console.log("-------22222222--");
        item.num = 1;
      } else if (item.num != "" && item.num > item.specsMixDesc.num) {
        console.log("---------");
        item.num = item.specsMixDesc.num;
      } else if (item.num != "") {
        console.log("-------11111--");
      }
    },

    onAdd(item) {
      if (item.num < item.specsMixDesc.num) {
        item.num = item.num + 1;
      }
    },
  },
});
