
import { Goods } from "@/api/zmkm";
import { searchGoods, getAdScrollBanners } from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WeekPreferenceView",
  components: { Pager },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      weekPreferenceGoods: [] as Goods[],

      n: 0, //默认图片是第一张开始
      imgArray: [] as any,
      timer: {},
    };
  },
  async mounted() {
    await this.getGoods();
    await this.getBanner();
    this.play();
  },
  watch: {
    page() {
      this.getGoods();
    },
  },
  methods: {
    async getBanner() {
      const adScrollBanners = await getAdScrollBanners(3);
      this.imgArray = adScrollBanners; //.map((asb) => asb.image);
    },

    bannerJump(item) {
      console.log(item);
      if (item.urlType == 0) {
        this.$router.push(item.pcUrl);
      } else if (item.urlType == 1) {
        window.open(item.url, "_blank");
      }
    },

    play() {
      this.timer = setInterval(this.autoPlay, 5000); //用定时器控制每张图的显示时间
    },
    autoPlay() {
      this.$data.n++;
      if (this.imgArray.length == this.n) {
        this.n = 0;
      }
    },
    clickImg(index: number) {
      this.n = index; //显示当前点击的图片
    },

    async getGoods() {
      const weekPreferenceGoods = await searchGoods({
        isInWeekPreference: true,
        page: this.page,
      });
      console.log(weekPreferenceGoods);

      weekPreferenceGoods.list.forEach((item,index,arr) => {
        item.priceMin = item.price;
      })

      this.pager = weekPreferenceGoods;
      this.weekPreferenceGoods = weekPreferenceGoods.list;
    },
  },
});
