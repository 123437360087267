
import { SearchRecommend } from "@/api/zmkm";
import {
  ApiResponseError,
  authPwd,
  authRegister,
  authSms,
  authTel,
  getSearchRecommends,
  RMemberInfo,
  updateMemberInfo,
  getCfgLogo,
  wxScanLogin,
  getCartGoods,
  //getMessages
} from "@/api/zmkm-api";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import wxlogin from "vue-wxlogin";

export default defineComponent({
  name: "Header",
  props: {},
  data: () => {
    return {
      settle: ["/settlement", "", ""],
      isLoginShow: false,
      isSmsLogin: true,
      isRegisterShow: false,
      isForgetPwdShow: false,
      isWxLoginShow: false,
      isBind: false,
      searchKeywords: "",
      searchRecommends: [] as SearchRecommend[],
      tel: "",
      code: "",
      password: "",
      repeatPassword: "",

      mobile: "",
      webOpenid: "",
      unionId: "",
      //accessToken:'',
      gender:0,
      nickname:'',
      avatar:'',
      isAccept: false,
      isAcceptR: false,
      codeStr: "获取验证码",
      codeStatus: false,
      historyList: [],
      historyVisible: false,
      talkId: "",
      wechatcode: "" as any,

      //cartGoodsNum: 0,

      logo: "" as string,

      //body:{} as any
    };
  },

  components: {
    wxlogin,
  },

  async mounted() {
    console.log("重新加载");

    if (this.memberInfo) {
      this.$store.dispatch("getMessagesList");
    }

    if (!this.memberInfo && this.$store.state.talkId != "") {
      //this.isRegisterShow = true;
      this.talkId = this.$store.state.talkId; //this.getQueryVariable('talk') as string;
    }

    //获取微信code
    this.wechatcode = this.getQueryVariable("code") as string;

    if (this.wechatcode) {
      //alert(this.wechatcode);
      //talkId: parseInt(this.talkId),
      let memberInfo = (await wxScanLogin({ code: this.wechatcode })) as any;

      if (memberInfo.memberAccount) {
        this.$store.commit("setMemberInfo", memberInfo);
      } else {
        //alert("mei you shou ji hao");
        this.webOpenid = memberInfo.openid;
        //this.accessToken = memberInfo.accessToken;
        this.unionId = memberInfo.unionId;

        this.gender =  memberInfo.gender;

        this.nickname =  memberInfo.nickname;

        this.avatar =  memberInfo.avatar;

        this.isBind = true;
        return;
      }

      //this.isLoginShow = false;
      this.$store.dispatch("getMessagesList");
      if (!this.memberInfo.memberAccount.authStatus) {
        this.$router.push("./prove");
        this.$message.show({
          message: "登录成功，请先进行首营认证~",
        });
      } else {
        this.$message.show({
          message: "登录成功~",
        });
      }
    }

    let body = document.querySelector("body") as any;
    body.addEventListener(
      "click",
      () => {
        this.historyVisible = false;
      },
      false
    );

    let result = localStorage.getItem("history") as string;
    if (result) {
      let historyList = JSON.parse(result);
      if (historyList && historyList.length > 0) {
        this.historyList = historyList;
      }
    }

    this.searchRecommends = await getSearchRecommends();
    console.log("search")
    console.log(this.searchRecommends);

    this.getCfgLogo();
  },

  computed: {
    isMaskShow() {
      return (
        this.isLoginShow ||
        this.isRegisterShow ||
        this.isForgetPwdShow ||
        this.isWxLoginShow ||
        this.isBind ||
        this.$store.state.isMaskShow ||
        this.$store.state.talkId != ""
      );
    },
    isLoginEnable() {
      if (this.isSmsLogin) {
        return this.tel.trim().length && this.code.trim().length;
      } else {
        return this.tel.trim().length && this.password.trim().length;
      }
    },
    isRegisterEnable() {
      return (
        this.tel.trim().length &&
        this.code.trim().length &&
        this.password.trim().length &&
        this.repeatPassword.trim().length
      );
    },
    ...mapState(["cfg", "memberInfo"]),
  },
  methods: {

    

    //绑定手机号
    async onBindTel() {
      let memberInfo = (await wxScanLogin({
        code: "",
        mobile: this.tel,
        num: this.code,
        webOpenid: this.webOpenid,
        //accessToken:this.accessToken,
        unionId: this.unionId,
        gender: this.gender,
        nickname:this.nickname,
        avatar:this.avatar

        //talkId: "",
      })) as any;

      this.$store.commit("setMemberInfo", memberInfo);

      this.$store.dispatch("getMessagesList");
      if (!this.memberInfo.memberAccount.authStatus) {
        this.$router.push("./prove");
        this.$message.show({
          message: "登录成功，请先进行首营认证~",
        });
      } else {
        this.$message.show({
          message: "登录成功~",
        });
      }


      this.isBind = false;

      console.log(memberInfo, "memberInfo");
    },


    async getCfgLogo() {
      this.logo = await getCfgLogo();
    },

    closeRegister() {
      this.isRegisterShow = false;
      this.$store.commit("setTalk", "");
      this.talkId = "";
    },

    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },

    dezhengc() {
      this.isAccept = !this.isAccept;
    },

    zhuzhengc() {
      this.isAcceptR = !this.isAcceptR;
    },

    onSendSms() {
      if (this.tel == "") {
        this.$message.show({ message: "请输入手机号" });
        return;
      }
      if (!/^1\d{10}$/.test(this.tel)) {
        this.$message.show({ message: "请输入正确的手机号" });
        return;
      }
      this.codeStatus = true;

      authSms({
        tel: this.tel,
      }).then(() => {
        this.$message.show({ message: "验证码发送成功" });
        this.countDownTimer();
      });
    },

    // 获取验证码
    countDownTimer: function () {
      //let that = this;
      let second = 60;
      this.codeStr = second + "s后重发";
      let timer = setInterval(() => {
        second -= 1;
        if (second > 0) {
          this.codeStr = second + "s后重发";
        } else {
          clearInterval(timer);
          this.codeStr = "获取验证码";
          this.codeStatus = false;
        }
      }, 1000);
    },

    async onLogin() {
      try {
        let memberInfo: RMemberInfo;

        if (!this.tel) {
          this.$message.show({ message: "请输入登录手机号" });
          return;
        }

        if (!/^1\d{10}$/.test(this.tel)) {
          this.$message.show({ message: "请输入正确的手机号" });
          return;
        }

        if (this.isSmsLogin) {
          if (!this.code) {
            this.$message.show({ message: "请输入验证码" });
            return;
          }

          if (!/^\d{6}$/.test(this.code)) {
            this.$message.show({ message: "验证码错误" });
            return;
          }

          if (!this.isAccept) {
            this.$message.show({
              message: "请先阅读并勾选《隐私协议》和《用户协议》",
            });
            return;
          }

          memberInfo = await authTel({
            tel: this.tel,
            num: parseInt(this.code),
            talkId: parseInt(this.talkId),
          });
        } else {
          if (!this.isAccept) {
            this.$message.show({
              message: "请先阅读并勾选《隐私协议》和《用户协议》",
            });
            return;
          }

          memberInfo = await authPwd({
            tel: this.tel,
            password: this.password,
            talkId: parseInt(this.talkId),
          });
        }

        this.$store.commit("setMemberInfo", memberInfo);
        this.isLoginShow = false;

        this.$store.dispatch("getMessagesList");

        if (!this.memberInfo.memberAccount.authStatus) {
          this.$router.push("./prove");

          this.$message.show({
            message: "登录成功，请先进行首营认证~",
          });
        } else {
          this.$message.show({
            message: "登录成功~",
          });
        }
      } catch (e) {
        if (e instanceof ApiResponseError) {
          //alert(e.response.message);
          this.$message.show({ message: e.response.message! });
        }
      }
    },
    async onLogout() {
      this.$store.commit("setMemberInfo", undefined);
      localStorage.removeItem("token");
      window.sessionStorage.clear();
      this.$router.push("/");
      this.tel = "";
      this.code = "";
      this.password = "";
    },
    async onRegister() {
      if (!this.tel) {
        this.$message.show({ message: "请输入手机号码" });
        return;
      }

      if (!/^1\d{10}$/.test(this.tel)) {
        this.$message.show({ message: "请输入正确的手机号" });
        return;
      }

      if (!this.code) {
        this.$message.show({ message: "请输入验证码" });
        return;
      }

      if (!/^\d{6}$/.test(this.code)) {
        this.$message.show({ message: "验证码错误" });
        return;
      }

      if (!this.password) {
        this.$message.show({ message: "请输入密码" });
        return;
      }

      if (this.password !== this.repeatPassword) {
        this.$message.show({ message: "两次密码输入不一致" });
        return;
      }

      if (!this.isAcceptR) {
        this.$message.show({
          message: "请先阅读并勾选《隐私协议》和《用户协议》",
        });
        return;
      }
      try {
        await authRegister({
          tel: this.tel,
          num: parseInt(this.code),
          password: this.password,
          talkId: parseInt(this.talkId),
        });
        this.$message.show({ message: "注册成功" });
        this.$store.commit("setTalk", "");
        this.talkId = "";
        this.isRegisterShow = false;
        this.isLoginShow = true;
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.$message.show({ message: e.response.message! });
        }
      }
    },

    async onUpdatePass() {
      if (!this.tel) {
        this.$message.show({ message: "请输入手机号" });
        return;
      }

      if (!/^1\d{10}$/.test(this.tel)) {
        this.$message.show({ message: "请输入正确的手机号" });
        return;
      }

      if (!this.code) {
        this.$message.show({ message: "请输入验证吗" });
        return;
      }

      if (!/^\d{6}$/.test(this.code)) {
        this.$message.show({ message: "验证码错误" });
        return;
      }

      if (!this.password) {
        this.$message.show({ message: "请输入密码" });
        return;
      }

      if (this.password !== this.repeatPassword) {
        this.$message.show({ message: "两次密码输入不一致" });
        return;
      }

      try {
        await updateMemberInfo({
          tel: this.tel,
          num: parseInt(this.code),
          password: this.password,
        });

        this.$message.show({ message: "操作成功" });

        this.isForgetPwdShow = false;
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.$message.show({ message: e.response.message! });
        }
      }
    },

    goPersonal() {
      this.$router.push("./personal");
    },
    //清空历史记录
    clearHistory() {
      localStorage.removeItem("history");
      this.historyList.length = 0;
    },

    deleteKeyword(keyword) {
      let historyList = this.historyList as any;
      let index = historyList.indexOf(keyword);
      this.historyList.splice(index, 1);
      localStorage.setItem("history", JSON.stringify(historyList));
    },

    onSearch() {
      if (this.searchKeywords != "") {
        this.historyVisible = false;
        let historyList = JSON.parse(localStorage.getItem("history") as string);
        if (historyList && historyList.length > 0) {
          let index = historyList.indexOf(this.searchKeywords);
          if (index > -1) {
            historyList.splice(index, 1);
          }
          historyList.unshift(this.searchKeywords);
        } else {
          historyList = [];
          historyList.push(this.searchKeywords);
        }
        this.historyList = historyList;

        localStorage.setItem("history", JSON.stringify(historyList));

        this.$router.push({
          name: "searchresult",
          params: { keywords: this.searchKeywords || " " },
        });
      }
    },
  },
});
