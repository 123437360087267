
import { Goods } from "@/api/zmkm";
import { searchGoods, getAdScrollBanners } from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WholeSaleAreaView",
  components: { Pager },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      wholeSaleAreaGoods: [] as Goods[],
      n: 0, //默认图片是第一张开始
      imgArray: [] as any,
      timer: {},
      keywords: "",
    };
  },
  async mounted() {
    await this.getGoods();
    await this.getBanner();
    this.play();
  },
  watch: {
    page() {
      this.getGoods();
    },
  },
  methods: {
    async getBanner() {
      const adScrollBanners = await getAdScrollBanners(2);
      this.imgArray = adScrollBanners; //.map((asb) => asb.image);
    },

    bannerJump(item) {
      console.log(item);
      if (item.urlType == 0) {
        this.$router.push(item.pcUrl);
      } else if (item.urlType == 1) {
        window.open(item.url, "_blank");
      }
    },

    play() {
      this.timer = setInterval(this.autoPlay, 5000); //用定时器控制每张图的显示时间
    },
    autoPlay() {
      this.$data.n++;
      if (this.imgArray.length == this.n) {
        this.n = 0;
      }
    },
    clickImg(index: number) {
      this.n = index; //显示当前点击的图片
    },

    onSearch() {
      //if (this.keywords != "") {

      this.getGoods();
      //}
    },

    async getGoods() {
      const wholeSaleAreaGoods = await searchGoods({
        keywords: this.keywords,
        isInWholesaleArea: true,
        page: this.page,
      });
      this.pager = wholeSaleAreaGoods;
      this.wholeSaleAreaGoods = wholeSaleAreaGoods.list;
    },
  },
});
