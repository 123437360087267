
import {
  ApiResponseError,
  createInvoice,
  getOrderDetail,
  ROrderDetail,
  getMemberInfo,
} from "@/api/zmkm-api";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvoiceApplyView",
  data() {
    return {
      orderDetail: undefined as ROrderDetail | undefined,
      invoice: {
        invoiceType: 1,
        riseType: 1,
        bankOfDeposit: "",
        bankAccount: "",
        registeredAddress: "",
        tel: "",
        image: "",
      } as Parameters<typeof createInvoice>[0],
    };
  },

  async mounted() {
    this.orderDetail = await getOrderDetail(
      parseInt(this.$route.params.orderId as string)
    );

    let memberInfo = await getMemberInfo();
    this.invoice.riseType = memberInfo.memberAccount.authFirmType as number;
    
  },
  
  methods: {
    async onCommit() {
      const {
        firmName,
        tax,
        invoiceType,
        registeredAddress,
        tel,
        bankOfDeposit,
        bankAccount,
      } = this.invoice;
      let regFirmName = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!firmName?.trim().length) {
        this.$message.show({ message: "请输入发票抬头" });
        return;
      }

      if (!regFirmName.test(this.invoice.firmName)) {
        this.$message.show({ message: "发票抬头有误,请输入2-20个汉字" });
        return;
      }

      if (!tax?.trim().length) {
        this.$message.show({ message: "请输入单位税号" });
        return;
      }

      if (invoiceType == 2 && registeredAddress == "") {
        this.$message.show({ message: "请填写注册地址" });
        return;
      }

      if (invoiceType == 2 && tel == "") {
        this.$message.show({ message: "请填写注册电话" });
        return;
      }

      if (tel && !/^1\d{10}$/.test(tel)) {
        this.$message.show({ message: "号码格式有误" });
        return;
      }

      if (invoiceType == 2 && bankOfDeposit == "") {
        this.$message.show({ message: "请填写开户银行" });
        return;
      }


      if (invoiceType == 2 && bankAccount == "") {
        this.$message.show({ message: "请填写银行账号" });
        return;
      }
      
      try {
        await createInvoice({
          ...this.invoice,
          ordersId: this.orderDetail!.id,
        });
        this.$message.show({ message: "申请成功" });
        this.$router.back();
      } catch (e) {
        if (e instanceof ApiResponseError) {
          this.$message.show({ message: e.response.message! });
        }
      }
    },
  },
});
