
import { defineComponent } from "vue";
import { getCfgAboutUs } from "@/api/zmkm-api";
export default defineComponent({
  name: "aboutview",
  data() {
    return {
      str: "",
    };
  },

  mounted() {
    this.getCfgAboutUs();
  },

  components: {},

  methods: {
    async getCfgAboutUs() {
      this.str = await getCfgAboutUs();
    },
  },
});
