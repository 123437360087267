
import CouponListView from "@/views/personal/components/CouponList.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "CouponView",
  components: { CouponListView },
  data() {
    return {
      type: 0,
      tabCurrentIndex: 0,
      tabs: [
        { name: "tab_1", label: "全部", type: undefined, enable: true },
        { name: "tab_2", label: "可使用", type: 1, enable: false },
        { name: "tab_3", label: "已过期", type: 2, enable: false },
        { name: "tab_4", label: "已使用", type: 3, enable: false },
      ],
    };
  },
  methods: {
    onTabSelect(index: number) {
      this.tabCurrentIndex = index;
      this.tabs[index].enable = true;
    },
  },
});
