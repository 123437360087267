
import { getInvoices, RInvoice } from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "InvoiceList",
  components: { Pager },
  props: {
    status: {
      type: Number,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewDetail(invoice: RInvoice) {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    preview(invoice: RInvoice) {
      return true;
    },
  },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      invoices: [] as RInvoice[],
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    async getInvoices() {
      const invoices = await getInvoices({
        status: this.status,
        page: this.page,
      });
      this.pager = invoices;
      this.invoices = invoices.list;
    },
    //订单详情
    orderDetail(invoice) {
      this.$router.push({
        name: "orderdetail",
        params: { orderId: invoice.ordersId },
      });
    },

    viewDetail(invoice: RInvoice) {
      this.$emit("viewDetail", invoice);
    },

    preview(invoice: RInvoice) {
      this.$emit("preview", invoice);
    },
  },
});
