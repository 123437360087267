
import { getMemberCoupons, RInvoice, RMemberCoupon } from "@/api/zmkm-api";
import Pager from "@/components/Pager.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CouponListView",
  components: { Pager },
  props: {
    type: {
      type: Number,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    use(coupon: RMemberCoupon) {
      return true;
    },
  },
  data() {
    return {
      pager: undefined as any,
      page: 1,
      coupons: [] as RMemberCoupon[],
      warnVisible: false,
    };
  },
  mounted() {
    this.getCoupons();
  },
  watch: {
    page() {
      this.getCoupons();
    },
  },
  methods: {
    async getCoupons() {
      const coupons = await getMemberCoupons({
        type: this.type,
        page: this.page,
      });
      this.pager = coupons;

      coupons.list.forEach((item) => {
        if (item.usagTime) {
          item["type"] = 3;
        } else {
          item["type"] = this.checkTime(
            item.effectiveTimeBeg,
            item.effectiveTimeEnd
          ) as number;
        }
      });

      this.coupons = coupons.list.map((item) => {
        item.effectiveTimeBeg = item.effectiveTimeBeg
          ?.slice(0, 10)
          .replace(/-/g, ".");
        item.effectiveTimeEnd = item.effectiveTimeEnd
          ?.slice(0, 10)
          .replace(/-/g, ".");
        return item;
      });
    },

    checkTime(effectiveTimeBeg, effectiveTimeEnd) {
      let nowTime = new Date().getTime();
      let startTime = new Date(effectiveTimeBeg).getTime();
      let endTime = new Date(effectiveTimeEnd).getTime();
      if (nowTime > endTime) {
        return 2;
      } else if (nowTime < endTime && nowTime > startTime) {
        return 1;
      } else {
        return 0;
      }
    },

    viewDetail(invoice: RInvoice) {
      this.$emit("viewDetail", invoice);
    },

    preview(invoice: RInvoice) {
      this.$emit("preview", invoice);
    },
  },
});
