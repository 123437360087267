
import {
  cancelAfterSaleOrder,
  confirmOrder,
  getAfterSaleDetail,
  getLogisticsCompanies,
  setAfterSaleExpress,
} from "@/api/zmkm-api";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AfterDetail",
  data() {
    return {
      sn: "",
      logisticsCoId: 4,
      order: {} as any,
      exressCompany: [] as any,
      cancelVisible: false,
      cancelFinishVisible: false,
      commentVisible: false,
      commentFinishVisible: false,
      confirmVisible: false,
      statusArr: {
        "-1": "申请中",
        "0": "卖家已同意退货退款，请上传物流单号",
        "1": "当前退款订单已关闭",
        "2": "退款成功",
      },
      warnDisable: false,
      warnMessage: "",
    };
  },
  async mounted() {
    //this.reasons = await getReasons();
    //this.reasonId = this.reasons[0]?.id || 0;
    await this.getOrderDetail();

    //await this.getExpressCompany();
  },
  methods: {
    async getOrderDetail() {
      this.order = await getAfterSaleDetail(
        parseInt(this.$route.params.orderId as string)
      );

      console.log(this.order, "-----");
    },

    async getExpressCompany() {
      this.exressCompany = await getLogisticsCompanies();
      console.log(this.exressCompany, "------------------------");
    },

    navBack() {
      this.$router.go(-1);
    },

    onSureCancel() {
      this.cancelFinishVisible = true;
    },

    onExpress() {
      this.$router.push({
        name: "afterexpress",
        params: { orderId: this.order!.id },
      });
    },

    async onCancel() {
      await cancelAfterSaleOrder(this.order.id);
      this.cancelFinishVisible = false;
      await this.getOrderDetail();
    },

    async onConfirm() {
      await confirmOrder(this.order!.id);
      await this.getOrderDetail();
      this.confirmVisible = false;
    },

    async setExpressInfo() {
      console.log(this.logisticsCoId);
      if (this.sn == "") {
        this.jumpWarn("请填写快递单号");
        return;
      }
      await setAfterSaleExpress(this.order!.id, {
        logisticsCoId: this.logisticsCoId,
        sn: this.sn,
      });
      this.commentVisible = false;
      this.getOrderDetail();
    },

    jumpWarn(title: any) {
      this.warnMessage = title;
      this.warnDisable = true;
      setTimeout(() => {
        this.warnDisable = false;
      }, 1000);
    },
    //查看物流
    lookExpress() {},
  },
});
